import { Heading, Stack, Image, Text, Button } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { Link, useLocation } from 'react-router-dom';

import exclamation from 'assets/exclamation.svg';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';

const AgreementNotAccepted = () => {
  const { pathname } = useLocation();

  return (
    <FullScreenLayout>
      <Stack spacing={14} alignItems="center" margin="0 auto" py={10}>
        <Image height="250px" src={exclamation} />
        <Stack spacing={10}>
          <Heading size="2xl" as="h1">
            Agreements not accepted
          </Heading>
          <Text>In order to place orders, you must first accept our data privacy agreements.</Text>
          <Button
            alignSelf={'center'}
            as={Link}
            to={`/consent?redirectUrl=${window._ENV_.VITE_KC_REDIRECT}${pathname}`}
            onClick={() => {
              mixpanel.track('Agreement Button Clicked');
            }}
          >
            Review Agreements
          </Button>
        </Stack>
      </Stack>
    </FullScreenLayout>
  );
};

export default AgreementNotAccepted;

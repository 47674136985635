import { useState } from 'react';

import { Box, Button, HStack, Heading, Icon, Stack } from '@ecoinvent/ui';
import { FiEdit } from 'react-icons/fi';

import AccountInfoView from 'components/AccountInfoView';
import LoginInfoView from 'components/LoginInfoView';
import AppCard from 'components/shared/AppCard';
import PageHead from 'components/shared/PageHead';
import UserProfileForm from 'components/UserProfileForm';
import useAuthUser from 'hooks/data/useAuthUser';
import useUserInfo from 'hooks/data/useUserInfo';
import useUserMutations from 'hooks/data/useUserMutations';

const Info = () => {
  const { updateUser } = useUserMutations();
  const { data: user, isLoading } = useUserInfo();
  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();
  const [editingProfile, setEditingProfile] = useState(false);
  if (isLoading || isAuthLoading || !user || !authUser) {
    return <Box w="100%" />;
  }

  return (
    <Stack width="100%" maxWidth="container.lg" spacing={6} py={6}>
      <PageHead title="Account Details" />

      <Heading as="h4" flex={1}>
        Account Details
      </Heading>

      <AppCard>
        <Heading fontSize={'xl'} as="h5" flex={1}>
          Login info
        </Heading>
        <LoginInfoView user={user} />
      </AppCard>

      <AppCard>
        <HStack justifyContent={'space-between'}>
          <Heading fontSize={'xl'} as="h5">
            Personal details
          </Heading>
          {!editingProfile && (
            <Button
              variant={'subtle'}
              size="sm"
              aria-label="edit"
              leftIcon={<Icon as={FiEdit} />}
              isDisabled={!authUser?.agreement_accepted}
              onClick={() => setEditingProfile(true)}
            >
              Update
            </Button>
          )}
        </HStack>
        {editingProfile ? (
          <UserProfileForm
            user={user}
            onCancel={() => setEditingProfile(false)}
            onUpdate={(u) => {
              updateUser.mutate(u);
              setEditingProfile(false);
            }}
          />
        ) : (
          <AccountInfoView user={user} />
        )}
      </AppCard>
    </Stack>
  );
};

export default Info;

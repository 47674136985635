import React from 'react';

import { Stack, Alert, AlertIcon, AlertTitle, AlertDescription, Text } from '@ecoinvent/ui';

const AddonNotSupported = () => {
  return (
    <Stack>
      <Alert size="sm" py={3} borderRadius={'md'} variant="left-accent">
        <Stack spacing={3}>
          <Stack direction="row">
            <AlertIcon />

            <Stack direction="column" spacing={0}>
              <AlertTitle>Upgrading with Add-ons</AlertTitle>
              <AlertDescription>
                <Text>
                  We currently don’t support upgrading licences with add-ons via credit card. Please contact sales via the
                  button below to send an upgrade request and we’ll reach out.
                </Text>
              </AlertDescription>
            </Stack>
          </Stack>
        </Stack>
      </Alert>
    </Stack>
  );
};

export default AddonNotSupported;

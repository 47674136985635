import { AxiosError, AxiosResponse } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import api from 'api/api';

import { LicenceUpgradeResponse } from './types';

type LicenceUpgradePriceParams = UseQueryOptions<AxiosResponse<LicenceUpgradeResponse>, AxiosError> & {
  id: string;
};

const fetchUpgradeLicencePrice = async (licenceId: string) => {
  const resp = await api.get<LicenceUpgradeResponse>(`/shop/licences/${licenceId}/upgrades`);
  return resp;
};

const useLicenceUpgradePriceResponse = ({ id, enabled, ...rest }: LicenceUpgradePriceParams) => {
  return useQuery<AxiosResponse<LicenceUpgradeResponse>, AxiosError>(
    ['licenceUpgrade', id],
    () => fetchUpgradeLicencePrice(id),
    {
      enabled: enabled && !!id,
      retry: false,
      ...rest,
    }
  );
};

export default useLicenceUpgradePriceResponse;

import { OrderFormData } from 'components/LicenceOrderWizard/types';
import { Address, LicenceType } from 'hooks/data/types';
import { ExtendedLicenceOrder, ExtendedLicenceType } from 'hooks/data/useLicenceMutations/types';
import countries from 'utilities/countries';
import { isRegularLicenceType } from 'utilities/licences';

import { LicencePlan } from './types';

export const mapLicenceToDisplayName = (type: LicenceType, plan: LicencePlan): string => {
  if (isRegularLicenceType(type)) return type; // TODO: refactor types so we don't break everything

  switch (plan) {
    case 'standard':
      return type === LicenceType.Developer ? 'Developer Lite' : 'Enterprise Standard';
    case 'premium':
      return type === LicenceType.Developer ? 'Developer Pro' : 'Enterprise Premium';
    default:
      throw new Error('Invalid licence type / plan combination');
  }
};

export const mapLicenceTypeForSubmission = (type: LicenceType, plan: LicencePlan): ExtendedLicenceType => {
  if (isRegularLicenceType(type)) return type as any; // TODO: refactor types so we don't break everything

  switch (plan) {
    case 'standard':
      return type === LicenceType.Developer ? ExtendedLicenceType.DEVELOPER_LITE : ExtendedLicenceType.ENTERPRISE_STANDARD;
    case 'premium':
      return type === LicenceType.Developer ? ExtendedLicenceType.DEVELOPER_PRO : ExtendedLicenceType.ENTERPRISE_PREMIUM;
    default:
      throw new Error('Invalid licence type / plan combination');
  }
};

export const mapOrderForSubmission = (data: OrderFormData): ExtendedLicenceOrder => {
  const mappedAddress = {
    ...(data.address as Address | undefined),
    country: countries.find((c) => c.label === (data.address?.country as string))?.code ?? undefined,
  } as Address | undefined;

  return {
    licence_count: 1,
    licence_type: mapLicenceTypeForSubmission(data.licenceType, data.plan),
    address: mappedAddress,
    organization: data.organization,
    order_details: data.orderDetails,
    with_en15804: data.includeAddon,
    eula_accepted: data.eula_accepted,
    is_subsequent: data.is_subsequent,
    product_id: data.product_id,
  };
};

import { HStack, Link, Text, DetailListConfig, Button } from '@ecoinvent/ui';

import { KeycloakUpdateAction, LoginInfo } from './types';

const buildUpdateLink = (action: KeycloakUpdateAction) =>
  `${window._ENV_.VITE_KC_AUTHORITY}/protocol/openid-connect/auth?client_id=apollo-ui&redirect_uri=${window.location.href}&response_type=code&scope=openid&kc_action=${action}`;

export const editableLoginConfig: DetailListConfig<LoginInfo> = [
  {
    label: 'Email address',
    key: 'email',
    renderItem: (email) => (
      <HStack spacing={2} flexWrap={'wrap'}>
        <Text wordBreak={'break-all'}>{email}</Text>
        <Button as={Link} variant="link" href={buildUpdateLink('UPDATE_EMAIL')} size={'sm'}>
          Change
        </Button>
      </HStack>
    ),
  },
  {
    label: 'Password',
    key: 'password',
    renderItem: (password) => (
      <HStack spacing={2} flexWrap={'wrap'}>
        <Text>{password}</Text>
        <Button as={Link} variant="link" href={buildUpdateLink('UPDATE_PASSWORD')} size={'sm'}>
          Change
        </Button>
      </HStack>
    ),
  },
];

export const readOnlyLoginConfig: DetailListConfig<LoginInfo> = [
  {
    label: 'Email address',
    key: 'email',
  },
  {
    label: 'Password',
    key: 'password',
  },
];

import { Stack, DetailList } from '@ecoinvent/ui';

import { User } from 'hooks/data/types';
import useAuthUser from 'hooks/data/useAuthUser';

import { editableLoginConfig, readOnlyLoginConfig } from './loginConfigs';

type Props = {
  user: User;
};

const LoginInfoView = ({ user }: Props) => {
  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();

  const config = !isAuthLoading && authUser?.agreement_accepted ? editableLoginConfig : readOnlyLoginConfig;

  return (
    <Stack maxWidth="400px">
      <DetailList config={config} data={{ email: user.email, password: '*****' }} />
    </Stack>
  );
};

export default LoginInfoView;

import { Text, DetailListConfig } from '@ecoinvent/ui';
import { format } from 'date-fns';

import { Licence } from 'hooks/data/types';
import { computeLatestAccessibleVersion } from 'utilities/licences';

const pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const licenceConfig: DetailListConfig<Licence> = [
  {
    label: 'Type',
    key: 'type',
    renderItem: (v) => {
      return <Text textTransform={'capitalize'}>{v}</Text>;
    },
  },
  {
    label: 'Expiry date',
    key: 'attrs',
    shouldRender: (attrs: Licence['attrs']) => !!attrs.expiry_date,
    renderItem: (attrs: Licence['attrs']) => {
      return <Text>{format(attrs.expiry_date as string, 'PPP')}</Text>;
    },
  },
  {
    label: 'Latest version',
    key: 'attrs',
    shouldRender: (attrs: Licence['attrs']) => !!attrs.maintenance_end_date,
    renderItem: (attrs: Licence['attrs']) => {
      return <Text>{computeLatestAccessibleVersion(attrs.maintenance_end_date).version}</Text>;
    },
  },
  {
    label: 'Capacity',
    key: 'max_users',
    renderItem: (maxUsers: Licence['max_users']) => {
      return <Text>{pluralize(maxUsers, 'user')}</Text>;
    },
  },
  {
    label: 'Add-ons',
    key: 'groups',
    shouldRender: (groups: Licence['groups']) => groups.map((grp) => grp.group.name).filter((i) => i !== 'classic').length > 0,
    renderItem: (groups: Licence['groups']) => {
      const addons = groups.map((grp) => grp.group.name).filter((i) => i !== 'classic');
      return <Text>{addons.join(', ')}</Text>;
    },
  },
];

import { Stack, DetailList } from '@ecoinvent/ui';

import { User } from 'hooks/data/types';

import infoConfig from './infoConfig';

type Props = {
  user: User;
};

const AccountInfoView = ({ user }: Props) => {
  return (
    <Stack>
      <DetailList config={infoConfig} data={user} hideFalsyValues />
    </Stack>
  );
};

export default AccountInfoView;

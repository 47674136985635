import React from 'react';

import { Stack, Text } from '@ecoinvent/ui';

import AdditionalInfoField from '../fragments/AdditionalInfoField';
import AddressForm from '../fragments/AddressForm';
import OrganizationNameField from '../fragments/OrganizationNameField';
import PlanSelector from '../fragments/PlanSelector';

const ManualOrderDetails = () => {
  return (
    <Stack spacing={2}>
      <PlanSelector />
      <Stack spacing={2}>
        <OrganizationNameField />
      </Stack>
      <Text>Please tell us about your licence needs, and we'll reach out to see how we can best help you.</Text>
      <AdditionalInfoField />
      <AddressForm />
    </Stack>
  );
};

export default ManualOrderDetails;

import React from 'react';

import { Stack, StackProps, useColorModeValue } from '@ecoinvent/ui';

type Props = StackProps;

const AppCard = (props: Props) => {
  const bg = useColorModeValue('white', 'whiteAlpha.100');
  const borderColor = useColorModeValue('transparent', 'whiteAlpha.200');

  return (
    <Stack
      bg={bg}
      borderRadius={'lg'}
      border={'1px solid'}
      borderColor={borderColor}
      p={5}
      spacing={5}
      boxShadow={'sm'}
      {...props}
    />
  );
};

export default AppCard;

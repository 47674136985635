import { Button, Link, Stack, Text } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useFormContext } from 'react-hook-form';

import { LicenceType } from 'hooks/data/types';

import { PaymentMethod } from '../types';

import Addons from './AddOns';
import LicenceTypeSelector from './LicenceTypeSelector';

const FirstStep = () => {
  const { setValue, watch } = useFormContext();
  const licenceType = watch('licenceType');

  const onLicenceTypeSelected = (type: LicenceType) => {
    setValue('paymentMethod', type === LicenceType.Commercial ? PaymentMethod.CreditCard : PaymentMethod.Quote);
    setValue('licenceType', type);
  };

  return (
    <Stack spacing={6}>
      <Text>
        Choose the licence that best fits your needs.{' '}
        <Button
          as={Link}
          variant="link"
          onClick={() => mixpanel.track('Learn more about licences', { licenceType: LicenceType.Educational })}
          href={'https://ecoinvent.org/offerings/licences/'}
          isExternal
        >
          Learn more about ecoinvent licences
        </Button>
      </Text>

      <Stack spacing={12}>
        <LicenceTypeSelector onLicenceTypeSelected={onLicenceTypeSelected} selectedLicenceType={licenceType} />
        <Addons />
      </Stack>
    </Stack>
  );
};

export default FirstStep;

export type Group = {
  id: number;
  name: string;
  description: string;
};

export type LicenceGroup = {
  expiry_date: string;
  group: Group;
};

export type Address = {
  street: string;
  city: string;
  country: string;
  zip_code: string;
};

export type Invoice = {
  id: string;
  url: string;
};

export type LicenceOwner = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  organisation_name: string;
};

export type LicenceReseller = {
  id: number;
  name: string;
};

export type Licence = {
  id: number;
  has_open_extension_order: boolean;
  owner: LicenceOwner;
  reseller?: LicenceReseller;
  is_active: boolean;
  is_subsequent: boolean;
  type: string;
  attrs: {
    expiry_date?: string;
    maintenance_end_date?: string;
  };
  groups: LicenceGroup[];
  start_date: string;
  max_users: number;
  flavour: {
    id: number;
    name: string;
    description: string;
    display_name: string;
    has_file_access: boolean;
  };
  original_username: string | null;
  create_date: string;
  update_date: null;
};

export type UserLicence = {
  id: number;
  is_admin: boolean;
  licence: Licence;
};

export type User = {
  email: string;
  organisation_name: string;
  organisation_comment: string;
  organisation_code: string;
  is_admin: boolean;
  first_name: string;
  last_name: string;
  department: null;
  address: Address;
  billing_address: Address;
  id: number;
  username: string;
  create_date: string;
  failed_password_attempt_count: null;
  last_login: string;
  last_failed_login_attempt: null;
  last_edited: string;
  licences: UserLicence[];
  has_checkout_access: boolean;
  agreement_accepted: boolean;
};

export type UserUpdate = {
  address: Address;
  billing_address: Address;
  email: string;
  organisation_name: string;
  first_name: string;
  last_name: string;
  username: string;
};

export enum LicenceType {
  Commercial = 'Commercial',
  Educational = 'Educational',
  Developer = 'Developer',
  Enterprise = 'Enterprise',
}

export type LicenceOrder = {
  licence_type: LicenceType;
  licence_count: number;
  order_details?: string;
  with_en15804?: boolean;
  organization?: string;
  address?: Address;
  is_subsequent?: boolean;
  eula_accepted: boolean;
  product_id?: string;
};

export enum OrderStatus {
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
  EXPIRED = 'Expired',
  CLOSED_AUTOMATIC = 'Closed Automatically',
}

export enum CheckoutStatus {
  SUCCESS = 'success',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}

export type MaintenanceExtension = {
  maintenance_additional_months: number;
};

export type ExistingLicenceOrder = Pick<LicenceOrder, 'licence_type'> & {
  licence_type: LicenceType | null;
  id: string;
  created_at: string;
  close_date: string | null;
  number_of_months: number | null;
  status?: string;
  invoice?: Invoice;
};

export type LicenceUpgradeOrder = Pick<
  ExistingLicenceOrder,
  'id' | 'close_date' | 'created_at' | 'number_of_months' | 'status' | 'invoice'
> &
  Pick<UserLicence, 'licence'>;

export type DetailedLicenceOrder = LicenceOrder &
  Pick<ExistingLicenceOrder, 'id' | 'close_date' | 'created_at' | 'licence_type' | 'status' | 'invoice'>;

export type DetailedOrderResponse = LicenceUpgradeOrder | DetailedLicenceOrder;

export type LicenceUpgradeResponse = {
  price: number;
  upgrade_months: number;
};

export type Agreement = {
  id: string;
  name: string;
  url: string;
};

export enum FeatureNames {
  OnlinePayments = 'online_payments',
  LicenceUpgrades = 'licence_upgrades',
}

export interface Feature {
  name: FeatureNames;
  value: boolean;
}

import { Stack, Text, useColorModeValue } from '@ecoinvent/ui';

import { User, UserLicence } from '../hooks/data/types';

import LicenceCardItem from './LicenceCardItem/LicenceCardItem';

type Props = {
  user: User;
};

export default function UserLicenceList({ user }: Props) {
  const helperTextColor = useColorModeValue('gray.500', 'gray.400');
  if (!user.licences?.length) {
    return (
      <Stack>
        <Text color={helperTextColor}>You don't currently have any licences.</Text>
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      {user.licences.map((userlicence: UserLicence) => (
        <LicenceCardItem key={userlicence.id} user={user} licence={userlicence.licence} />
      ))}
    </Stack>
  );
}

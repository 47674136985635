import { useToast } from '@ecoinvent/ui';
import { useMutation, useQueryClient } from 'react-query';

import api from 'api/api';

import { ExtendedLicenceOrder, LicenceExtensionData } from './types';

const requestQuote = async (licenceOrder: ExtendedLicenceOrder) => {
  const resp = await api.post('/orders/licence', {
    licence_count: licenceOrder.licence_count,
    licence_type: licenceOrder.licence_type,
    order_details: licenceOrder.order_details,
    with_en15804: licenceOrder.with_en15804,
    organization: licenceOrder.organization,
    address: licenceOrder.address,
    is_subsequent: licenceOrder.is_subsequent,
    eula_accepted: licenceOrder.eula_accepted,
  });
  return resp;
};

const sendExtensionRequest = async ({ licenceId, duration }: LicenceExtensionData) => {
  const resp = await api.post(`/orders/licences/${licenceId}/maintenance_extension`, {
    additional_months: duration,
  });
  return resp;
};

const useLicenceMutations = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const onError = () => {
    toast({
      status: 'error',
      description: 'Something went wrong, please try again later',
      title: 'Oh no...',
    });
  };

  const orderLicence = useMutation(requestQuote, {
    onError,
  });

  const requestExtension = useMutation(sendExtensionRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userInfo']);
      queryClient.invalidateQueries(['openOrders']);
      toast({ status: 'success', title: 'Upgrade request sent.', description: "You'll hear from us shortly!" });
    },
    onError,
  });

  return { orderLicence, requestExtension };
};

export default useLicenceMutations;

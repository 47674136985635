import { fallbackLicencePrices } from 'components/LicenceOrderWizard/licenceData';
import { LicenceType } from 'hooks/data/types';
import { StripeProductMap, productIdMap } from 'hooks/data/useStripeProducts';

export const priceForLicenceType = (licenceType: LicenceType, stripeProducts?: StripeProductMap) => {
  switch (licenceType) {
    case LicenceType.Commercial:
      return stripeProducts?.[productIdMap[LicenceType.Commercial]]?.price ?? fallbackLicencePrices[licenceType];
    default:
      return fallbackLicencePrices[licenceType];
  }
};

import React from 'react';

import { Stack } from '@ecoinvent/ui';

import Footer from 'components/Footer';

type Props = { includeFooter?: boolean; children?: React.ReactNode };

const FullScreenLayout = ({ includeFooter = true, children }: Props) => {
  return (
    <Stack width="100%" justify={'space-between'} minH={'100vh'}>
      {children ?? <Stack flex={1} />}
      {includeFooter && <Footer />}
    </Stack>
  );
};

export default FullScreenLayout;

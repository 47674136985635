import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import { Agreement } from './types';

const fetchAgreements = async (country: string) => {
  const { data } = await api.get('/agreements', { params: { country } });
  return data;
};

const useAgreements = (country: string) => {
  return useQuery<Agreement[], AxiosError>(['agreements', country], () => fetchAgreements(country), {
    enabled: !!country,
  });
};

export default useAgreements;

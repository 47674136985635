import { Text, DetailListConfig } from '@ecoinvent/ui';
import { format } from 'date-fns';

import { DetailedLicenceOrder } from 'hooks/data/types';

const orderConfig: DetailListConfig<DetailedLicenceOrder> = [
  {
    label: 'Order type',
    key: 'id',
    renderItem: () => <Text>Licence</Text>,
  },
  {
    label: 'Licence type',
    key: 'licence_type',
  },
  {
    label: 'Includes EN15804',
    key: 'with_en15804',
    renderItem: (value) => <Text>{value ? 'Yes' : 'No'}</Text>,
  },
  {
    label: 'Is additional licence',
    key: 'is_subsequent',
    renderItem: (value) => <Text>{value ? 'Yes' : 'No'}</Text>,
  },
  {
    label: 'Organisation',
    key: 'organization',
  },
  {
    label: 'Placed on',
    key: 'created_at',
    renderItem: (value) => <Text>{format(value, 'PPP')}</Text>,
  },
];

export default orderConfig;

import { Badge, Skeleton, Stack, Text } from '@ecoinvent/ui';

import AppCard from 'components/shared/AppCard';
import { DetailedLicenceOrder, DetailedOrderResponse, LicenceUpgradeOrder, OrderStatus } from 'hooks/data/types';

import LicenceOrderFields from './LicenceOrderFields';
import MaintenanceExtensionFields from './MaintenanceExtensionFields';

type Props = { order: DetailedOrderResponse | undefined; isLoading: boolean };

const OrderDetailView = ({ order, isLoading }: Props) => {
  const isMaintenanceExtension = !!(order as LicenceUpgradeOrder)?.number_of_months;
  const orderStatus =
    order?.close_date || order?.status === OrderStatus.CLOSED_AUTOMATIC || order?.status === OrderStatus.CLOSED;
  return isLoading ? (
    <AppCard>
      <Stack spacing={1} padding={2}>
        {new Array(5).fill(0).map((_, i) => (
          <Stack key={`item_${i.toString()}`} alignItems="center" direction="row" justifyContent="space-between">
            <Skeleton variant="text" width={100 + (20 % (i + 1))} />
            <Skeleton variant="text" width={120 + (13 % (i + 5))} />
          </Stack>
        ))}
      </Stack>
    </AppCard>
  ) : (
    <AppCard>
      <Stack spacing={1}>
        {isMaintenanceExtension ? (
          <MaintenanceExtensionFields order={order as LicenceUpgradeOrder} />
        ) : (
          <LicenceOrderFields order={order as DetailedLicenceOrder} />
        )}
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Text fontWeight={'semibold'} fontSize={'sm'}>
            Status
          </Text>
          <Badge
            variant="solid"
            fontSize="x-small"
            colorScheme={order?.status === OrderStatus.EXPIRED ? 'gray' : orderStatus ? 'green' : 'blue'}
            rounded="full"
            px={2}
          >
            {order?.status === OrderStatus.EXPIRED ? 'Expired' : orderStatus ? 'Completed' : 'In Progress'}
          </Badge>
        </Stack>
      </Stack>
    </AppCard>
  );
};

export default OrderDetailView;

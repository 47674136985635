import React from 'react';

import { FormControl, FormLabel, Select, Input, FormErrorMessage, HStack, Box, useColorModeValue } from '@ecoinvent/ui';
import { Controller, useFormContext } from 'react-hook-form';

import { User } from 'hooks/data/types';
import countries from 'utilities/countries';

import { OrderFormData } from '../types';

const AddressForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<OrderFormData | User>();

  const textColor = useColorModeValue('black', 'white');
  const helperTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <>
      <FormControl isInvalid={!!errors.address?.street}>
        <FormLabel>Address</FormLabel>
        <Input {...register('address.street', { required: true })} placeholder="Street name and number" />
        <FormErrorMessage>{'Please enter your street name and number'}</FormErrorMessage>
      </FormControl>

      <HStack>
        <FormControl isInvalid={!!errors.address?.city}>
          <FormLabel hidden>City</FormLabel>
          <Input {...register('address.city', { required: true })} placeholder="City" />
          <FormErrorMessage>{'Please enter your city'}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.address?.zip_code} maxW="30%">
          <FormLabel hidden>Zip code</FormLabel>
          <Input {...register('address.zip_code', { required: true })} placeholder="Zip code" />
          <FormErrorMessage textOverflow={'ellipsis'} isTruncated>
            {'Please enter your zip'}
          </FormErrorMessage>
        </FormControl>
      </HStack>
      <FormControl size="small" isInvalid={!!errors.address?.country}>
        <FormLabel hidden>Country</FormLabel>
        <Controller
          name="address.country"
          control={control}
          rules={{
            validate: (v) => !!v && v !== 'Country',
            required: true,
          }}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              id="country-label"
              placeholder="Country"
              value={value}
              onChange={onChange}
              color={value === '' || !value ? helperTextColor : textColor}
              {...rest}
            >
              {countries.map((c) => (
                <Box as={'option'} color={textColor} key={c.code} value={c.label}>
                  {c.label}
                </Box>
              ))}
            </Select>
          )}
        />

        <FormErrorMessage>{'Please select a country'}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default AddressForm;

import { Text, Alert, AlertIcon, AlertDescription, Link, Button } from '@ecoinvent/ui';

const RenewSubscriptionAlert = () => {
  return (
    <Alert status="info" variant={'left-accent'} size="sm" py={2} borderRadius={'md'}>
      <AlertIcon />
      <AlertDescription>
        <Text>
          To renew your subscription, please{' '}
          <Button variant="link" as={Link} isExternal href="mailto:sales@ecoinvent.org" fontWeight="semibold">
            contact us
          </Button>
          .
        </Text>
      </AlertDescription>
    </Alert>
  );
};

export default RenewSubscriptionAlert;

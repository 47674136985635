import React from 'react';

import { FormControl, Stack, FormLabel, RadioGroup, Radio, FormHelperText, Link } from '@ecoinvent/ui';
import { Controller, useFormContext } from 'react-hook-form';

import { OrderFormData } from '../types';

const PlanSelector = () => {
  const { control, watch } = useFormContext<OrderFormData>();
  const licenceType = watch('licenceType');
  return (
    <FormControl as={Stack} spacing={1} maxWidth="500px">
      <FormLabel as="legend">Please select a plan:</FormLabel>
      <Controller
        rules={{ required: true }}
        control={control}
        name="plan"
        render={({ field }) => (
          <RadioGroup dir="row" {...field}>
            <Radio value="standard">{licenceType === 'Developer' ? 'Lite' : 'Standard'} </Radio>
            <Radio value="premium">{licenceType === 'Developer' ? 'Pro' : 'Premium'} </Radio>
          </RadioGroup>
        )}
      />
      <FormHelperText>
        Learn more about different plans{' '}
        <Link href="https://ecoinvent.org/offerings/licences/" target="_blank">
          here.
        </Link>
      </FormHelperText>
    </FormControl>
  );
};

export default PlanSelector;

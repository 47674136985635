import { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
} from '@ecoinvent/ui';
import { FormProvider, useForm } from 'react-hook-form';

import countries from 'utilities/countries';

import { User } from '../hooks/data/types';

import AddressForm from './LicenceOrderWizard/fragments/AddressForm';

export function UserProfileForm({
  user,
  onUpdate,
  onCancel,
}: {
  user: User;
  onUpdate: (user: User) => void;
  onCancel: () => void;
}) {
  const formControl = useForm({ defaultValues: user });

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = formControl;

  const [hasBillingAddress, setHasBillingAddress] = useState(user.billing_address !== null);
  useEffect(() => {
    if (hasBillingAddress) {
      register('billing_address.city');
      register('billing_address.country');
      register('billing_address.zip_code');
      register('billing_address.street');
    } else {
      unregister('billing_address.city');
      unregister('billing_address.country');
      unregister('billing_address.zip_code');
      unregister('billing_address.street');
    }
  }, [register, unregister, hasBillingAddress]);
  const submit = (data: any) => {
    if (!hasBillingAddress) {
      data.billing_address = null;
    }
    onUpdate(data);
  };
  return (
    <FormProvider {...formControl}>
      <Stack as="form" onSubmit={handleSubmit(submit)}>
        <HStack>
          <FormControl isInvalid={!!errors.first_name}>
            <FormLabel>First name</FormLabel>
            <Input {...register('first_name', { required: true })} />
            <FormErrorMessage>{'Please enter your first name'}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.last_name}>
            <FormLabel>Last name</FormLabel>
            <Input {...register('last_name', { required: true })} />
            <FormErrorMessage>{'Please enter your last name'}</FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl isInvalid={!!errors.organisation_name}>
          <FormLabel>Organisation</FormLabel>
          <Input {...register('organisation_name')} />
        </FormControl>
        <AddressForm />

        <FormControl>
          <Checkbox isChecked={hasBillingAddress} onChange={() => setHasBillingAddress(!hasBillingAddress)}>
            Use different address for billing
          </Checkbox>
        </FormControl>
        {hasBillingAddress && (
          <>
            <FormControl isInvalid={!!errors.billing_address?.street}>
              <FormLabel>Billing Address</FormLabel>
              <Input {...register('billing_address.street', { required: true })} placeholder="Street" />
              <FormErrorMessage>{'This field is required'}</FormErrorMessage>
            </FormControl>

            <HStack>
              <FormControl isInvalid={!!errors.billing_address?.city}>
                <Input {...register('billing_address.city', { required: true })} placeholder="City" />
                <FormErrorMessage>{'This field is required'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors.billing_address?.zip_code} maxW="20%">
                <Input {...register('billing_address.zip_code', { required: true })} placeholder="Zip code" />
                <FormErrorMessage>{'This field is required'}</FormErrorMessage>
              </FormControl>
            </HStack>
            <FormControl size="small" isInvalid={!!errors.billing_address?.country}>
              <Select
                id="country-label"
                placeholder="Country"
                {...register('billing_address.country', { required: 'Please choose a country' })}
              >
                <option value={undefined}>{undefined}</option>
                {countries.map((c) => (
                  <option key={c.code} value={c.label}>
                    {c.label}
                  </option>
                ))}
              </Select>
              <FormHelperText>{errors.address?.country && 'Field is required.'}</FormHelperText>
            </FormControl>
          </>
        )}
        <Stack direction="row" spacing={2} mt={3}>
          <Button variant="subtle" width={'100%'} onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button type="submit" width={'100%'}>
            Save
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default UserProfileForm;

import {
  Button,
  HStack,
  Link,
  Skeleton,
  Stack,
  StackDirection,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@ecoinvent/ui';

import AppCard from 'components/shared/AppCard';
import { LicenceType } from 'hooks/data/types';
import useStripeProducts from 'hooks/data/useStripeProducts';
import { formatPrice, isRegularLicenceType } from 'utilities/licences';
import { priceForLicenceType } from 'utilities/orders/checkout';

import { licenceTypeLabels, licenceTypeShortDescriptions } from '../licenceData';

type Props = {
  selectedLicenceType: LicenceType;
  onLicenceTypeSelected: (arg: LicenceType) => void;
};

const LicenceTypeSelector = ({ selectedLicenceType, onLicenceTypeSelected }: Props) => {
  const { data: stripeProducts, isLoading: areStripePricesLoading } = useStripeProducts();

  const licenceChoices = Object.entries(LicenceType) as [string, LicenceType][];

  const direction = useBreakpointValue({ base: 'column', lg: 'row' }) as StackDirection;
  const flex = useBreakpointValue({ base: '1 1 auto', lg: '1 1 0' }) as string;

  const borderColor = useColorModeValue('blue.500', 'blue.300');
  const hoverColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.100');

  return (
    <Stack spacing={6}>
      <Stack spacing={2} direction={direction} justifyContent="space-evenly">
        {licenceChoices.map(([key, licenceType]) => {
          const price = priceForLicenceType(licenceType, stripeProducts);

          return (
            <AppCard
              key={key}
              _hover={
                isRegularLicenceType(licenceType)
                  ? { cursor: 'pointer', bg: selectedLicenceType !== key ? 'blackAlpha.50' : hoverColor }
                  : {}
              }
              flex={flex}
              borderColor={selectedLicenceType === key ? borderColor : 'blackAlpha.200'}
              borderWidth={selectedLicenceType === key ? 2 : 1}
              borderStyle={'solid'}
              boxShadow={'none'}
              userSelect={'none'}
              aria-roledescription="licence type selector button"
            >
              <Stack
                height="100%"
                borderRadius={'8px'}
                spacing={4}
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
                onClick={() => {
                  isRegularLicenceType(licenceType) && onLicenceTypeSelected(licenceType);
                }}
              >
                <Stack spacing={2}>
                  <Text fontSize={'xl'} fontWeight={'semibold'} as="div">
                    {licenceTypeLabels[licenceType]}
                  </Text>
                  {licenceTypeShortDescriptions[licenceType]}
                </Stack>
                <Skeleton isLoaded={!areStripePricesLoading} width={'100%'}>
                  <Stack direction={{ base: 'column', lg: 'row' }} spacing={2} justifyContent={'space-between'} width="100%">
                    <HStack spacing={2}>
                      <Text fontSize="sm">from</Text>
                      <Text fontSize={'xl'} fontWeight={'semibold'}>
                        {formatPrice(price)}
                      </Text>
                    </HStack>
                    {!isRegularLicenceType(licenceType) && (
                      <Button as={Link} variant={'subtle'} color={borderColor} size="sm" href="mailto:sales@ecoinvent.org">
                        Contact us
                      </Button>
                    )}
                  </Stack>
                </Skeleton>
              </Stack>
            </AppCard>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default LicenceTypeSelector;

import { Stack, Text, Link, Button, Heading, Box, Divider, Image } from '@ecoinvent/ui';

import logo from 'assets/logo_small.png';
import success from 'assets/success.svg';
import AppCard from 'components/shared/AppCard';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import PageHead from 'components/shared/PageHead';

const Success = () => {
  return (
    <FullScreenLayout>
      <PageHead title="Order Successful" />
      <Stack spacing={10} py={10} maxW={'lg'} w="90%" alignSelf={'center'} textAlign={'center'}>
        <Box as="img" className="logo" alt="logo" src={logo} margin="0 auto" maxWidth="177px" />
        <AppCard spacing={6} p={10}>
          <Stack alignItems={'center'} justifyContent={'center'} spacing={2}>
            <Image py={10} width={'240px'} src={success} />
            <Heading as="h1" size="lg">
              Order Success!
            </Heading>
            <Text>Your payment was processed successfully.</Text>
          </Stack>
          <Divider />
          <Stack alignItems={'center'} justifyContent={'center'} spacing={2}>
            <Text fontWeight={'semibold'}>Your licence will be activated in the next few minutes. </Text>
            <Text>
              Please <Link href="mailto:sales@ecoinvent.org">contact our sales team </Link>
              if you have any questions or concerns.
            </Text>
          </Stack>
          <Stack alignItems={'center'} justifyContent={'center'} spacing={4}>
            <Button as={Link} href={window._ENV_.VITE_APOLLO_URL}>
              Go to ecoQuery
            </Button>
            <Button as={Link} variant="link" fontWeight="medium" href="/">
              Back to Overview
            </Button>
          </Stack>
        </AppCard>
      </Stack>
    </FullScreenLayout>
  );
};

export default Success;

import React from 'react';

import { Stack, Alert, AlertTitle, AlertDescription, Button, Text } from '@ecoinvent/ui';
import { BsCart3 } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import licenceVersions from 'utilities/licenceVersions';

const NewLicenceRequired = () => {
  return (
    <Stack>
      <Alert size="sm" py={3} borderRadius={'md'}>
        <Stack spacing={3}>
          <Stack direction="row">
            <Stack direction="column" spacing={0}>
              <AlertTitle>New licence required</AlertTitle>
              <AlertDescription>
                <Text>
                  Based on your current access, to upgrade to {licenceVersions[0].version} you'll need to purchase a new
                  licence.
                </Text>
              </AlertDescription>
            </Stack>
          </Stack>
          <Stack>
            <Button leftIcon={<BsCart3 />} as={Link} to={'/order'}>
              Purchase a new licence
            </Button>
          </Stack>
        </Stack>
      </Alert>
    </Stack>
  );
};

export default NewLicenceRequired;

import { useState } from 'react';

import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Link,
  Select,
  Spinner,
  Stack,
  Text,
} from '@ecoinvent/ui';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import logo from 'assets/logo_small.png';
import AppCard from 'components/shared/AppCard';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import PageHead from 'components/shared/PageHead';
import { Agreement } from 'hooks/data/types';
import useAgreementMutations from 'hooks/data/useAgreementMutations';
import useAgreements from 'hooks/data/useAgreements';
import useAuthUser from 'hooks/data/useAuthUser';
import countries from 'utilities/countries';

const Consent = () => {
  const [country, setCountry] = useState(''); // TODO: use "DE" as default value
  const { data: agreements, isLoading: areAgreementsLoading } = useAgreements(country);
  const [params] = useSearchParams();
  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();

  const redirectUrl = params.get('redirectUrl');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Record<string, boolean>>({ shouldUnregister: true, defaultValues: {} });

  const { acceptAllAgreements } = useAgreementMutations();

  const formAgreements = watch();

  const onSubmit = async () => {
    await acceptAllAgreements.mutateAsync({ country, agreements: agreements as Agreement[] });
    if (redirectUrl) window.location.href = redirectUrl;
  };

  if (isAuthLoading) return <Spinner size="xl" speed={'0.85s'} thickness="3px" />;

  return (
    <FullScreenLayout>
      <PageHead title="Data Privacy Agreements" />
      <Stack spacing={10} maxWidth="lg" margin="0 auto" alignItems="stretch" py={10} width="90%">
        <Image alt="logo" src={logo} margin="0 auto" maxWidth="177px" />
        <AppCard>
          {authUser?.agreement_accepted ? (
            <Stack spacing={4} alignItems="center">
              <Heading as="h5" size="sm">
                You're all set!
              </Heading>
              <Text>You have no outstanding agreements to accept.</Text>
              <Link as={RouterLink} to={`${redirectUrl || '/'}`}>
                {redirectUrl ? 'Continue' : 'Go to Overview'}
              </Link>
            </Stack>
          ) : (
            <Stack spacing={8}>
              <Heading as="h4" size="lg">
                Agreements
              </Heading>
              <Stack spacing={2}>
                <Text>
                  Before using the ecoinvent Database, due to data protection law, we kindly ask you to accept the relevant data
                  protection terms.
                </Text>
                <Text>
                  ecoinvent has updated its data privacy terms based on the new Swiss Federal Act on Data Protection that came
                  into force on 1st of September 2023. Your access to the ecoQuery database requires your consent to these
                  terms. The EULA (of 1st of April 2022) remains unchanged.
                </Text>
              </Stack>

              <Stack spacing={2}>
                <Text fontWeight={'medium'}>First, tell us where you're located.</Text>

                <FormControl isInvalid={!!errors.country}>
                  <FormLabel hidden htmlFor="country">
                    Country
                  </FormLabel>
                  <Select
                    id="country"
                    placeholder="Select a country..."
                    {...register('country', { required: 'Please choose a country' })}
                    onChange={(e) => setCountry(e.target.value as string)}
                  >
                    {countries.map((c) => (
                      <option key={c.code} value={c.code}>
                        {c.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
                </FormControl>
              </Stack>
              {areAgreementsLoading ? (
                <Stack alignItems="center">
                  <Spinner size="xl" speed={'0.85s'} thickness="3px" />
                </Stack>
              ) : (
                agreements && (
                  <Stack spacing={6} as="form" onSubmit={handleSubmit(onSubmit)}>
                    {agreements.length ? (
                      <Stack spacing={2}>
                        <Text fontWeight={'medium'}>Please accept the following agreements:</Text>
                        {agreements.map((item) => (
                          <FormControl key={item.id} isInvalid={!!errors[item.id as any]}>
                            <Stack direction="row" key={item.id} spacing={0.5} alignItems="center">
                              <Checkbox {...register(item.id, { required: `Please accept the ${item.name}` })}>
                                <Text>
                                  I accept the{' '}
                                  <Link href={item.url} target="_blank">
                                    {item.name}.
                                  </Link>{' '}
                                </Text>
                              </Checkbox>
                            </Stack>
                            <FormErrorMessage>{errors[item.id as any]?.message}</FormErrorMessage>
                          </FormControl>
                        ))}
                      </Stack>
                    ) : (
                      <Stack spacing={2} textAlign={'center'}>
                        <Text>Oops, there are actually no agreements outstanding.</Text>
                        <Text fontSize="sm" fontWeight={'medium'}>
                          Please click "Finalise" below to continue.
                        </Text>
                      </Stack>
                    )}
                    <Stack spacing={2}>
                      <Button
                        isDisabled={
                          Object.values(formAgreements).length > 1 && !Object.values(formAgreements).every((check) => !!check)
                        }
                        type="submit"
                      >
                        Finalise
                      </Button>
                      <Text fontSize={'xs'} color="gray.500">
                        By clicking this button, you confirm your acceptance of the above agreements.
                      </Text>
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>
          )}
        </AppCard>
      </Stack>
    </FullScreenLayout>
  );
};

export default Consent;

import React from 'react';

import { ColorModeScript } from '@ecoinvent/ui';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import theme from 'themes/ecoinvent';

import { setupAnalyticsIfAllowed, disableAnalytics, initMixpanel } from 'utilities/consent/cookies';

import App from './App';

import './fonts.css';

const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  // eslint-disable-next-line no-underscore-dangle
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    Object.entries(DEV_TOOLS).forEach(([key, value]) => {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    });
  }
};

if (window._ENV_.NODE_ENV === 'production') {
  disableReactDevTools();
}

['production'].includes(import.meta.env.MODE) &&
  Sentry.init({
    dsn: 'https://372188fd66dbb6ebc79ebc202042c739@o4505124399546368.ingest.sentry.io/4505873157718016',
    tracesSampleRate: 1,
    environment: window._ENV_.NODE_ENV,

    tracePropagationTargets: [
      'https://api.accounts.ecoinvent.org/',
      'https://accounts.ecoinvent.org',
      'https://ecoquery.ecoinvent.org/',
    ],
    replaysOnErrorSampleRate: 0.25,
  });

// mixpanel is initialised but no tracking until consent given
initMixpanel();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

window.addEventListener('CookiebotOnAccept', setupAnalyticsIfAllowed);
window.addEventListener('CookiebotOnDecline', disableAnalytics);

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>
);

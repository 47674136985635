import React from 'react';

import { Stack } from '@ecoinvent/ui';

import FinalizeStep from 'components/LicenceOrderWizard/FinalizeStep';
import FirstStep from 'components/LicenceOrderWizard/FirstStep';
import OrderDetailStep from 'components/LicenceOrderWizard/OrderDetailStep';
import SuccessStep from 'components/LicenceOrderWizard/SuccessStep';

type Props = {
  activeStep: number;
};

const StepContent = ({ activeStep }: Props) => {
  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <FirstStep />;
      case 1:
        return <OrderDetailStep />;
      case 2:
        return <FinalizeStep />;
      case 3:
        return <SuccessStep />;
      default:
        return null;
    }
  };

  return <Stack>{renderStepContent(activeStep)}</Stack>;
};

export default StepContent;

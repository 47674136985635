import { LicenceType } from 'hooks/data/types';

import { licenceVersions } from './licenceVersions';

const isRegularLicenceType = (licenceType: LicenceType) =>
  [LicenceType.Commercial, LicenceType.Educational].includes(licenceType);

const computeLatestAccessibleVersion = (maintenanceEndDate: string | undefined) => {
  if (!maintenanceEndDate) return licenceVersions[0];

  return licenceVersions.filter((item) => maintenanceEndDate && new Date(item.releaseDate) <= new Date(maintenanceEndDate))[0];
};

const formatPrice = (price: number) =>
  (price / 100).toLocaleString(undefined, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

export { isRegularLicenceType, computeLatestAccessibleVersion, formatPrice };

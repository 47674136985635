import 'App.css';

import 'focus-visible/dist/focus-visible';
import '@fontsource/inter';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';

import { ThemeProvider } from '@ecoinvent/ui';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import theme from 'themes/ecoinvent';

import ApiInterceptor from 'components/ApiInterceptor';
import Layout from 'components/shared/Layout';
import Info from 'pages/Info';
import Redirect from 'pages/licences/Redirect';
import Error from 'pages/misc/Error';
import NotFound from 'pages/misc/NotFound';
import AllOrders from 'pages/orders/AllOrders';
import NewOrder from 'pages/orders/NewOrder';
import SpecificOrder from 'pages/orders/SpecificOrder';
import Success from 'pages/orders/Success';
import Overview from 'pages/Overview';
import Consent from 'pages/setup/Consent';
import EmailVerified from 'pages/setup/EmailVerified';
import oidcConfig from 'utilities/auth/oidc';

// Do not keep session storage data.
// This will ensure that new access token is fetched on page refresh.
window.onbeforeunload = () => {
  sessionStorage.clear();
};

const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider {...oidcConfig}>
      <ApiInterceptor>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <ThemeProvider theme={theme} toastOptions={{ defaultOptions: { position: 'bottom-left' } }}>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    <Route index element={<Overview />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/orders/:id" element={<SpecificOrder />} />
                    <Route path="/orders" element={<AllOrders />} />
                  </Route>
                  <Route path="/order" element={<NewOrder />} />
                  <Route path="/upgrade" element={<EmailVerified />} />
                  <Route path="/consent" element={<Consent />} />
                  <Route path="/order/checkout/success" element={<Success />} />
                  <Route path="/licences/:id/upgrade/redirect" element={<Redirect />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="/error" element={<Error />} />
                  <Route path="*" element={<Navigate replace to="/404" />} />
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </HelmetProvider>
        </QueryClientProvider>
      </ApiInterceptor>
    </AuthProvider>
  );
}

export default App;

import React from 'react';

import { Stack, Text } from '@ecoinvent/ui';
import { format } from 'date-fns';

import { Licence } from 'hooks/data/types';
import { computeLatestAccessibleVersion } from 'utilities/licences';

type Props = { licence: Licence };

const LicenceExpiry = ({ licence }: Props) => {
  switch (licence.type) {
    case 'perpetual':
      return (
        <Stack spacing={0}>
          <Text>
            This licence grants permanent access to every version of the ecoinvent database up to{' '}
            {computeLatestAccessibleVersion(licence.attrs.maintenance_end_date).version}.
          </Text>
          <Text>To access newer versions, you'll need to upgrade this licence.</Text>
        </Stack>
      );
    case 'subscription':
      return (
        <Stack spacing={0}>
          <Text>This licence expires on {format(licence.attrs.expiry_date as string, 'PPP')}.</Text>
          <Text>To continue using the ecoinvent database after this date, you need to renew your subscription.</Text>
        </Stack>
      );
    case 'partner':
      return (
        <Stack spacing={0}>
          <Text>This licence expires on {format(licence.attrs.expiry_date as string, 'PPP')}.</Text>
          <Text>To continue using the ecoinvent database after this date, please reach out to us to extend.</Text>
        </Stack>
      );
    default:
      return null;
  }
};

export default LicenceExpiry;

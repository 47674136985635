import { Stack, Box, Text, Link, useColorModeValue, useColorMode, DarkMode, Icon, IconButton, HStack } from '@ecoinvent/ui';
import { getYear } from 'date-fns';
import { FiMoon, FiSun } from 'react-icons/fi';

import logosign from 'assets/ecoinvent_logo_sign_neg.svg';

const Footer = () => {
  const footerBg = useColorModeValue('blue.500', 'blue.700');
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Stack alignItems={'center'} py={4} bg={footerBg}>
      <HStack direction="row" alignItems="center" spacing={2} w="90%" maxWidth="container.xl">
        <HStack spacing={2}>
          <Box as="img" className="logo" alt="logo" src={logosign} maxWidth="40px" opacity={1} />
          <Text whiteSpace={'nowrap'} fontSize={'xs'} textAlign="left" width="100%" color="white">
            © ecoinvent {getYear(new Date())}
          </Text>
          <DarkMode>
            <IconButton
              aria-label="Toggle color mode"
              size="sm"
              variant={'ghost'}
              color="white"
              icon={<Icon as={colorMode === 'light' ? FiMoon : FiSun} />}
              onClick={toggleColorMode}
            />
          </DarkMode>
        </HStack>
        <Text fontSize={'xs'} textAlign="right" width="100%" color="white">
          We are a non-profit based in Zurich, Switzerland. Learn more at{' '}
          <Link color="white" href="https://ecoinvent.org" isExternal>
            ecoinvent.org
          </Link>
        </Text>
      </HStack>
    </Stack>
  );
};

export default Footer;

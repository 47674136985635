import { Box, Button, Link, Stack, Text, Image } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { Link as RouterLink } from 'react-router-dom';

import logo from 'assets/logo_small.png';
import success from 'assets/success.svg';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import PageHead from 'components/shared/PageHead';
import useUserInfo from 'hooks/data/useUserInfo';

const EmailVerified = () => {
  const { data: user, isLoading } = useUserInfo();

  if (isLoading || !user) {
    return <Box w="100%" />;
  }

  return (
    <FullScreenLayout>
      <PageHead title="Email Verification" />
      <Stack spacing={10} maxWidth="container.lg" margin="0 auto" alignItems="center" py={10} width="90%">
        <Stack spacing={10} alignItems="center">
          <Box as="img" className="logo" alt="logo" src={logo} margin="0 auto" maxWidth="177px" />
          <Image py={10} width={'240px'} src={success} />
          <Text textAlign="center" fontSize={'3xl'} fontWeight={'medium'}>
            Your email has been verified.
          </Text>
        </Stack>
        <Stack spacing={4} alignItems="center">
          <Button
            as={RouterLink}
            to={`/consent?redirectUrl=${window._ENV_.VITE_KC_REDIRECT}/order`}
            size="lg"
            onClick={() => {
              mixpanel.track('Order Button Clicked', { Page: 'Upgrade' });
            }}
          >
            Purchase a licence
          </Button>
          <Text>OR</Text>
          <Link as={RouterLink} to={`/consent?redirectUrl=${window._ENV_.VITE_APOLLO_URL}`}>
            Continue to ecoQuery
          </Link>
        </Stack>
      </Stack>
    </FullScreenLayout>
  );
};

export default EmailVerified;

import { BsBasket2Fill } from 'react-icons/bs';
import { GiSettingsKnobs } from 'react-icons/gi';
import { LuLayoutGrid } from 'react-icons/lu';

import { NavLink } from './types';

const appLinks: NavLink[] = [
  {
    label: 'Overview',
    path: '/',
    icon: LuLayoutGrid,
  },
  {
    label: 'Orders',
    path: '/orders',
    icon: BsBasket2Fill,
  },
  {
    label: 'Account Details',
    path: '/info',
    icon: GiSettingsKnobs,
  },
];

export default appLinks;

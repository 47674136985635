export type VersionMap = {
  version: string;
  releaseDate: string;
};

export const licenceVersions: readonly VersionMap[] = [
  {
    version: '3.10',
    releaseDate: '2023-11-28',
  },
  {
    version: '3.9.1',
    releaseDate: '2022-10-01',
  },
  {
    version: '3.9',
    releaseDate: '2022-10-01',
  },
  {
    version: '3.8',
    releaseDate: '2021-09-01',
  },
  {
    version: '3.7.1',
    releaseDate: '2020-09-01',
  },
  {
    version: '3.7',
    releaseDate: '2020-09-01',
  },
  {
    version: '3.6',
    releaseDate: '2019-09-01',
  },
  {
    version: '3.5',
    releaseDate: '2018-08-01',
  },
  {
    version: '3.4',
    releaseDate: '2017-10-01',
  },
  {
    version: '3.3',
    releaseDate: '2016-08-01',
  },
  {
    version: '3.2',
    releaseDate: '2015-11-01',
  },
  {
    version: '3.1',
    releaseDate: '2014-06-01',
  },
  {
    version: '3.01',
    releaseDate: '2013-06-01',
  },
  {
    version: '2.0',
    releaseDate: '2010-10-01',
  },
];

export default licenceVersions;

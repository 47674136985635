import { Alert, Stack, Heading, AlertIcon } from '@ecoinvent/ui';
import { useSearchParams } from 'react-router-dom';

import AgreementNotAccepted from 'components/AgreementNotAccepted';
import LicenceOrderWizard from 'components/LicenceOrderWizard';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import PageHead from 'components/shared/PageHead';
import { CheckoutStatus } from 'hooks/data/types';
import useAuthUser from 'hooks/data/useAuthUser';
import useUserInfo from 'hooks/data/useUserInfo';

const NewOrder = () => {
  const { data: user, isLoading } = useUserInfo();
  const [searchParams] = useSearchParams();

  const shouldRenderBanner = searchParams.get('status') === CheckoutStatus.CANCELLED;

  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();

  if (isLoading || !user || isAuthLoading || !authUser) {
    return <FullScreenLayout />;
  }

  if (!authUser.agreement_accepted) {
    return <AgreementNotAccepted />;
  }

  return (
    <FullScreenLayout>
      <PageHead title="New Order" />
      {shouldRenderBanner && (
        <Alert status="warning">
          <AlertIcon /> Your checkout was cancelled. To complete your order, please check out again.
        </Alert>
      )}
      <Stack justify={'space-between'} flex={1} maxWidth="container.xl" margin="0 auto" py={10} width="90%">
        <Stack spacing={6}>
          <Heading as="h1" size="xl">
            Order New Licence
          </Heading>
          <LicenceOrderWizard />
        </Stack>
      </Stack>
    </FullScreenLayout>
  );
};

export default NewOrder;

import { LicenceOrder } from '../types';

export type LicenceExtensionData = {
  licenceId: string;
  duration: number;
};

export enum ExtendedLicenceType {
  COMMERCIAL = 'Commercial',
  EDUCATIONAL = 'Educational',
  DEVELOPER_LITE = 'DeveloperLite',
  DEVELOPER_PRO = 'DeveloperPro',
  ENTERPRISE_STANDARD = 'EnterpriseStandard',
  ENTERPRISE_PREMIUM = 'EnterprisePremium',
}

export type ExtendedLicenceOrder = Omit<LicenceOrder, 'licence_type'> & {
  licence_type: ExtendedLicenceType;
};

import { Text, Link, DetailListConfig } from '@ecoinvent/ui';
import { format } from 'date-fns';

import { LicenceUpgradeOrder } from 'hooks/data/types';
import { computeLatestAccessibleVersion } from 'utilities/licences';

const upgradeConfig: DetailListConfig<LicenceUpgradeOrder> = [
  {
    label: 'Order type',
    key: 'id',
    renderItem: () => <Text>Upgrade</Text>,
  },
  {
    label: 'New version',
    key: 'created_at',
    renderItem: (value) => <Text>{computeLatestAccessibleVersion(value).version}</Text>,
  },
  {
    label: 'Organisation',
    key: 'licence',
    renderItem: (value: LicenceUpgradeOrder['licence']) => <Text>{value.owner.organisation_name}</Text>,
  },
  {
    label: 'Licence owner email',
    key: 'licence',
    renderItem: (value: LicenceUpgradeOrder['licence']) => (
      <Link href={`mailto:${value.owner.email}`}>{value.owner.email}</Link>
    ),
  },
  {
    label: 'Placed on',
    key: 'created_at',
    renderItem: (value) => <Text>{format(value, 'PPP')}</Text>,
  },
];

export default upgradeConfig;

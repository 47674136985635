import { Stack } from '@ecoinvent/ui';
import { Outlet } from 'react-router-dom';

import ConsentBanner from 'components/ConsentBanner';
import Sidebar from 'components/Sidebar';
import MobileSideBar from 'components/Sidebar/MobileSideBar';
import useAuthUser from 'hooks/data/useAuthUser';

const Layout = () => {
  const { data: authUser, isLoading } = useAuthUser();

  const shouldShowBanner = !isLoading && authUser && !authUser.agreement_accepted;

  return (
    <Stack maxW={'100%'} overflowY="hidden" flex={1}>
      <Stack spacing={0} maxH={'100vh'}>
        <MobileSideBar />
        <Stack direction="row" spacing={0} alignItems={'stretch'} overflowY="auto">
          <Sidebar />
          <Stack maxW="100%" w="100%" overflowY="auto">
            {shouldShowBanner && <ConsentBanner />}
            <Stack p={2} px={5} maxW="100%" w="100%" margin={'0 auto'} alignItems="center">
              <Outlet />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Layout;

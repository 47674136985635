import { Box, Button, Divider, HStack, Icon, Skeleton, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiPlus } from 'react-icons/bi';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import logo from 'assets/logo_small.png';
import logoWhite from 'assets/logo_small_white.png';
import useAuthUser from 'hooks/data/useAuthUser';
import appLinks from 'utilities/navigation/appLinks';

import UserMenu from './UserMenu';

type Props = {
  onLinkClick?: (route: string) => void;
};

const SidebarContent = ({ onLinkClick }: Props) => {
  const location = useLocation();

  const { data: authUser, isLoading } = useAuthUser();

  const doPathsMatch = (path: string, url: string) => {
    return [path, url].every((p) => p === '/') || (url.startsWith(path) && path.length > 1);
  };

  const sidebarBg = useColorModeValue('white', 'whiteAlpha.50');

  const isActiveBG = useColorModeValue('blackAlpha.50', 'whiteAlpha.200');
  const isActiveHover = useColorModeValue('blackAlpha.100', 'whiteAlpha.100');
  const isActiveColor = useColorModeValue('blue.500', 'blue.200');
  const isInactiveColor = useColorModeValue('gray.500', 'gray.300');

  const logoSrc = useColorModeValue(logo, logoWhite);

  return (
    <Stack spacing={0} height="100%" borderRadius={'xl'} bg={sidebarBg} boxShadow={'sm'}>
      <Stack py={6} px={4} direction="row" alignItems="center">
        <Box maxW={'180px'}>
          <RouterLink to="/">
            <img className="logo" alt="logo" src={logoSrc} />
          </RouterLink>
        </Box>
      </Stack>
      <Divider />

      <Stack height="100%" py={4} justifyContent="space-between">
        <Stack px={4}>
          {appLinks.map(({ label, path, icon }) => {
            const isActive = doPathsMatch(path, location.pathname);
            return (
              <HStack
                py={2}
                px={3}
                spacing={3}
                background={isActive ? isActiveBG : 'auto'}
                _hover={!isActive ? { bg: isActiveHover } : {}}
                color={isActive ? isActiveColor : isInactiveColor}
                as={RouterLink}
                to={path}
                key={path}
                borderRadius={'lg'}
                onClick={() => onLinkClick?.(path)}
                fontWeight={isActive ? 'bold' : 'normal'}
                transition={'all 0.1s ease-in-out'}
              >
                <Icon as={icon} fontSize={'xl'} />
                <Text>{label}</Text>
              </HStack>
            );
          })}
        </Stack>
        <Stack spacing={4} px={4} width="100%">
          <Skeleton isLoaded={!isLoading}>
            <Stack width="100%">
              <Button
                onClick={() => {
                  mixpanel.track('Order Button Clicked', { Page: 'Sidebar' });
                  onLinkClick?.('/order');
                }}
                as={authUser?.agreement_accepted ? RouterLink : undefined}
                to="/order"
                isDisabled={!authUser?.agreement_accepted}
                size="sm"
                leftIcon={<Icon as={BiPlus} />}
              >
                Order Licence
              </Button>
            </Stack>
          </Skeleton>
          <Divider />
          <UserMenu />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SidebarContent;

import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import { ecoinventTheme, extendTheme, systemFontStack, withDefaultColorScheme } from '@ecoinvent/ui';

const theme = extendTheme(
  {
    ...ecoinventTheme,
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          bg: mode('blackAlpha.50', 'gray.800')(props),
          textUnderlineOffset: '0.1em',
        },
      }),
    },
    fonts: {
      heading: `'PPMori', ${systemFontStack}`,
      body: `'Inter', ${systemFontStack}`,
    },
  },
  withDefaultColorScheme({
    colorScheme: 'blue',
    components: ['Link', 'Button', 'Input', 'Select', 'Checkbox', 'Radio', 'Switch', 'Spinner', 'Checkbox', 'Badge'],
  })
);

export default theme;

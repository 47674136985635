import { Stack, Button, Menu, MenuItem, MenuList, Icon, useColorMode, useColorModeValue } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { FiLogIn, FiLogOut, FiMoon, FiSun } from 'react-icons/fi';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

import EcoQueryIcon from './EcoQueryIcon';
import UserMenuButton from './UserMenuButton';

const UserMenu = () => {
  const auth = useAuth();
  const menuId = 'primary-search-account-menu';

  const { colorMode, toggleColorMode } = useColorMode();

  const iconColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack>
      {auth.isAuthenticated ? (
        <Menu id={menuId}>
          <Stack direction={'row'} alignItems={'center'}>
            <UserMenuButton
              id={menuId}
              isLoading={auth.isLoading}
              name={auth.user?.profile.name}
              email={auth.user?.profile.email}
              imageSrc={auth.user?.profile.picture}
            />
            <MenuList>
              <MenuItem
                onClick={toggleColorMode}
                icon={<Icon color={iconColor} fontSize={'lg'} as={colorMode === 'dark' ? FiSun : FiMoon} />}
              >
                Switch to {colorMode === 'dark' ? 'light' : 'dark'} mode
              </MenuItem>
              <MenuItem
                icon={<Icon color={iconColor} fontSize={'lg'} as={EcoQueryIcon} />}
                as={Link}
                to={window._ENV_.VITE_APOLLO_URL as string}
              >
                ecoQuery
              </MenuItem>
              <MenuItem
                icon={<Icon color={iconColor} fontSize={'lg'} as={FiLogOut} />}
                onClick={() => auth.signoutRedirect({ post_logout_redirect_uri: window.location.href })}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Stack>
        </Menu>
      ) : (
        <Button
          size="sm"
          leftIcon={<Icon as={FiLogIn} />}
          onClick={() => {
            mixpanel.track('Login (Header)');
            auth.signinRedirect({ state: window.location.href });
          }}
        >
          Log in
        </Button>
      )}
    </Stack>
  );
};

export default UserMenu;

import { Alert, AlertDescription, AlertIcon, Button, Stack } from '@ecoinvent/ui';
import { Link } from 'react-router-dom';

const ConsentBanner = () => {
  return (
    <Stack p={3} px={5}>
      <Alert status="warning" rounded="lg">
        <AlertIcon />
        <AlertDescription fontSize={'sm'}>
          As you have not accepted our Data Privacy Agreements, you currently cannot place orders or update your details.{' '}
          <Button
            variant="link"
            fontWeight="medium"
            colorScheme={'orange'}
            size="sm"
            as={Link}
            to={`/consent?redirectUrl=${window.location.href}`}
          >
            Fix now
          </Button>
        </AlertDescription>
      </Alert>
    </Stack>
  );
};

export default ConsentBanner;

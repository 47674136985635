import React from 'react';

import { Stack, HStack, Tooltip, Skeleton, Text, Link, Alert } from '@ecoinvent/ui';
import { BiInfoCircle } from 'react-icons/bi';

import { formatPrice } from 'utilities/licences';

type Props = {
  price: number | undefined;
  isLoading?: boolean;
};

const UpgradePrice = ({ price, isLoading }: Props) => {
  return (
    <Stack>
      <HStack justifyContent="flex-end">
        <Text as="span" fontWeight={'semibold'}>
          Upgrade cost
        </Text>
        <Tooltip
          label={
            <Stack justifyContent="center" textAlign="center">
              <Text as="span">
                This price is based on the latest version of the ecoinvent database you have access to.{' '}
                <Link color="blue.200" isExternal href="https://support.ecoinvent.org/licenses">
                  Learn more
                </Link>
              </Text>
            </Stack>
          }
          hasArrow
          closeDelay={2000}
          shouldWrapChildren
          placement="top"
          bg="gray.900"
          px={3}
          py={2}
          pointerEvents={'all'}
          borderRadius="md"
        >
          <BiInfoCircle />
        </Tooltip>
      </HStack>
      <Skeleton isLoaded={!isLoading} alignSelf="flex-end">
        {!price && !isLoading ? (
          <Alert status="info" size="sm" py={2} borderRadius={'md'} variant="left-accent">
            Something went wrong, please try again later
          </Alert>
        ) : (
          <Text fontWeight={'semibold'} fontSize="3xl">
            {!!price && formatPrice(price)}{' '}
            <Text as="span" fontWeight={'semibold'} fontSize="sm">
              ex. VAT
            </Text>
          </Text>
        )}
      </Skeleton>
    </Stack>
  );
};

export default UpgradePrice;

import { useState } from 'react';

import {
  Checkbox,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Heading,
  ModalHeader,
  ModalBody,
  Button,
} from '@ecoinvent/ui';
import { Controller, useFormContext } from 'react-hook-form';

import { formatPrice } from 'utilities/licences';

import { OrderFormData } from '../types';

const AddOns = () => {
  const [open, setOpen] = useState(false);
  const { control } = useFormContext<OrderFormData>();
  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <Stack spacing={8}>
      <Heading size="md" fontSize={'2xl'}>
        Choose Add-ons
      </Heading>
      <Stack spacing={4}>
        <Controller
          name="includeAddon"
          control={control}
          render={({ field: { value, ...rest } }) => {
            return (
              <Checkbox isChecked={value} {...rest}>
                {' '}
                <Text>
                  EN 15804{' '}
                  <strong>
                    (+
                    {formatPrice(60000)})
                  </strong>
                </Text>
              </Checkbox>
            );
          }}
        />
        <Stack spacing={0}>
          <Text>
            Recommended for everyone aiming to create and publish Environment Product Declaration (EPD) reports.{' '}
            <Button as={Link} variant="link" onClick={() => handleClickOpen()}>
              More details
            </Button>
          </Text>
          <Modal size="xl" isOpen={open} onClose={() => handleClickOpen()}>
            <ModalOverlay />
            <ModalContent pb={5}>
              <ModalCloseButton />
              <ModalHeader> The EN 15804 add-on</ModalHeader>
              <ModalBody>
                The EN 15804 add-on follows guidelines of the Technical Committee CEN/TC 350 and the directives of the European
                Commission regarding waste and end-of-waste state, including inventory indicators describing resource use,
                emissions and removals of carbon, waste categories and output flows; and a detailed overview of the assumptions
                and the calculation method for indicators.
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddOns;

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import { DetailedOrderResponse } from './types';

const fetchSpecificLicenceOrder = async (id: string) => {
  const { data } = await api.get(`/orders/${id}`);
  return data;
};

const useSpecificLicenceOrder = (id: string) => {
  return useQuery<DetailedOrderResponse, AxiosError>(['order', id], () => fetchSpecificLicenceOrder(id), {
    enabled: !!id,
    retry: false,
  });
};

export default useSpecificLicenceOrder;

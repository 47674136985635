import { Text, DetailListConfig } from '@ecoinvent/ui';

import { User } from 'hooks/data/types';

const infoConfig: DetailListConfig<User> = [
  {
    label: 'First name',
    key: 'first_name',
  },
  {
    label: 'Last name',
    key: 'last_name',
  },
  {
    label: 'Organisation',
    key: 'organisation_name',
  },
  {
    label: 'Address',
    key: 'address',
    renderItem: (address) => <Text>{[address.street, address.city, address.zip_code, address.country].join(', ')}</Text>,
  },
  {
    label: 'Billing address',
    key: 'billing_address',
    renderItem: (address) =>
      address && <Text>{[address.street, address.city, address.zip_code, address.country].join(', ')}</Text>,
  },
];

export default infoConfig;

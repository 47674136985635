import { Stack, Heading } from '@ecoinvent/ui';

import LicenceOrderTable from 'components/LicenceOrderTable';
import AppCard from 'components/shared/AppCard';
import PageHead from 'components/shared/PageHead';
import useLicenceOrders from 'hooks/data/useLicenceOrders';

const AllOrders = () => {
  const { data: orders, isLoading } = useLicenceOrders();

  return (
    <Stack width="100%" maxWidth="container.lg" whiteSpace={'break-spaces'} spacing={10} py={6}>
      <PageHead title="Orders" />

      <Stack spacing={6}>
        <Heading as="h4" flex={1}>
          Orders
        </Heading>

        <AppCard>
          <LicenceOrderTable orders={orders ?? []} isLoading={isLoading} />
        </AppCard>
      </Stack>
    </Stack>
  );
};

export default AllOrders;

import React from 'react';

import { Tr, Td, Skeleton, Table, Tbody } from '@ecoinvent/ui';

import LicenceTableHead from './LicenceTableHead';

const LicenceTableSkeleton = () => {
  const renderSkeleton = () => {
    return (
      <>
        {new Array(5).fill(0).map((_, i) => {
          return (
            <Tr key={`${i.toString()}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <Td>
                <Skeleton height="30px" />
              </Td>
              <Td>
                <Skeleton height="30px" />
              </Td>
              <Td>
                <Skeleton height="30px" />
              </Td>
              <Td>
                <Skeleton height="30px" />
              </Td>
              <Td>
                <Skeleton height="30px" />
              </Td>
              <Td>
                <Skeleton height="30px" />
              </Td>
            </Tr>
          );
        })}
      </>
    );
  };

  return (
    <Table aria-label="simple table">
      <LicenceTableHead />
      <Tbody>{renderSkeleton()}</Tbody>
    </Table>
  );
};

export default LicenceTableSkeleton;

import { Alert, Stack, AlertIcon, AlertDescription, AlertTitle } from '@ecoinvent/ui';

const SuccessStep = () => {
  return (
    <Stack spacing={2}>
      <Alert status="success" rounded="lg">
        <AlertIcon />
        <Stack spacing={0}>
          <AlertTitle>Success!</AlertTitle>
          <AlertDescription>
            Your order has been submitted. We'll reach out to you shortly to confirm your order and arrange payment.
          </AlertDescription>
        </Stack>
      </Alert>
    </Stack>
  );
};

export default SuccessStep;

import React from 'react';

import { Box, Text, FormControl, FormErrorMessage, Textarea, useColorModeValue } from '@ecoinvent/ui';
import { useFormContext } from 'react-hook-form';

import { isRegularLicenceType } from 'utilities/licences';

import { OrderFormData } from '../types';

const AdditionalInfoField = () => {
  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<OrderFormData>();

  const { licenceType } = getValues();

  const helperTextColor = useColorModeValue('gray.500', 'gray.400');

  const orderDetailsWatched = watch('orderDetails');
  return (
    <Box width="100%" position="relative">
      <FormControl isInvalid={!!errors.orderDetails}>
        <Textarea
          w="100%"
          minH={'100px'}
          resize={'vertical'}
          placeholder={
            isRegularLicenceType(licenceType)
              ? 'Enter any additional comments or requests for our sales team'
              : 'Describe your licence needs here...'
          }
          maxLength={300}
          noOfLines={2}
          {...register('orderDetails', {
            required: isRegularLicenceType(licenceType) ? false : 'Please describe your licence needs.',
          })}
        />
        <FormErrorMessage>{errors.orderDetails?.message}</FormErrorMessage>
      </FormControl>
      <Text bottom={0} right={5} position="absolute" fontSize={'xs'} color={helperTextColor} alignSelf="flex-end">
        {orderDetailsWatched?.length ?? 0} / 300
      </Text>
    </Box>
  );
};

export default AdditionalInfoField;

import { Stack, useBreakpointValue } from '@ecoinvent/ui';

import SidebarContent from './SidebarContent';

const drawerWidth = 320;

const Sidebar = () => {
  const display = useBreakpointValue({ base: 'none', md: 'flex' });

  return (
    <Stack as="nav" p={3} h="100vh" display={display} w={drawerWidth}>
      <SidebarContent />
    </Stack>
  );
};

export default Sidebar;

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import { ExistingLicenceOrder } from './types';

const fetchLicenceOrders = async () => {
  const { data } = await api.get('/orders');
  return data;
};

const useLicenseOrders = () => {
  return useQuery<ExistingLicenceOrder[], AxiosError>('openOrders', fetchLicenceOrders);
};

export default useLicenseOrders;

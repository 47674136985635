import { useToast } from '@ecoinvent/ui';
import { useMutation, useQueryClient } from 'react-query';

import api from 'api/api';

import { Agreement } from '../types';

const acceptAgreements = async (data: { country: string; agreements: Agreement[] }) => {
  const resp = await api.post('/agreements', {
    country: data.country,
    agreement_ids: data.agreements.map(({ id }) => id),
  });
  return resp;
};

const useAgreementMutations = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const acceptAllAgreements = useMutation(acceptAgreements, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userInfo']);
      queryClient.invalidateQueries(['authUser']);
      queryClient.invalidateQueries(['agreements']);
    },
    onError: () => {
      toast({ status: 'error', description: 'Something went wrong, please try again later', title: 'Oh no...' });
    },
  });

  return { acceptAllAgreements };
};

export default useAgreementMutations;

import { Stack, Text, useColorModeValue } from '@ecoinvent/ui';

type Props = { items: string[] };

const LicenceTypeDescription = ({ items }: Props) => {
  const itemColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack fontSize={'sm'} color={itemColor} as={'ul'} paddingInlineStart={'20px'} lineHeight={1.2} spacing={1}>
      {items.map((item) => (
        <Stack key={item} display={'list-item'} as="li">
          <Text>{item}</Text>
        </Stack>
      ))}
    </Stack>
  );
};

export default LicenceTypeDescription;

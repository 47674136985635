import React from 'react';

import {
  Step,
  StepIcon,
  StepNumber,
  StepStatus,
  StepTitle,
  Stepper,
  Stack,
  Heading,
  StepSeparator,
  StepIndicator,
} from '@ecoinvent/ui';
import * as Sentry from '@sentry/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AppCard from 'components/shared/AppCard';
import { LicenceType } from 'hooks/data/types';
import useFeatures from 'hooks/data/useFeatures';
import useStripeProducts from 'hooks/data/useStripeProducts';
import { ErrorReason } from 'utilities/errors/types';

import { stepHeadings } from './constants';
import StepContent from './steps/StepContent';
import StepFooter from './steps/StepFooter';
import { OrderFormData, PaymentMethod } from './types';

const LicenceOrderWizard = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { error } = useStripeProducts();
  const navigate = useNavigate();

  // pre-fetch features at the beginning of the order flow
  useFeatures();

  const methods = useForm<OrderFormData>({
    defaultValues: {
      licenceType: LicenceType.Commercial,
      paymentMethod: PaymentMethod.CreditCard,
      includeAddon: false,
      eula_accepted: true,
      is_subsequent: false,
      clientType: 'private',
      organization: undefined,
      plan: 'standard',
    },
  });

  const licenceType = methods.watch('licenceType');

  if (error) {
    Sentry.captureException(error);
    navigate(`/error?reason=${ErrorReason.PRODUCTS_NOT_LOADED}`, { replace: true });
  }

  return (
    <FormProvider {...methods}>
      <Stack as="form" spacing={8} width="100%">
        <Stepper index={activeStep}>
          {(licenceType === 'Commercial' ? stepHeadings : stepHeadings).map((label) => {
            return (
              <Step key={label}>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                </StepIndicator>
                <StepTitle>{label}</StepTitle>
                <StepSeparator />
              </Step>
            );
          })}
        </Stepper>
        <AppCard p={7} spacing={8}>
          <Heading size="md" fontSize={'2xl'} as="h3">
            {activeStep < stepHeadings?.length ? stepHeadings[activeStep] : 'Order Submitted'}
          </Heading>
          <StepContent activeStep={activeStep} />
          <StepFooter activeStep={activeStep} onStepChange={(step) => setActiveStep(step)} />
        </AppCard>
      </Stack>
    </FormProvider>
  );
};

export default LicenceOrderWizard;

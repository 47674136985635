import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import api from 'api/api';

import { User } from './types';

const fetchUserInfo = async () => {
  const { data } = await api.get('/user/info', {});
  return data;
};

const useUserInfo = (options?: UseQueryOptions<User, AxiosError>) => {
  const userInfoQuery = useQuery<User, AxiosError>('userInfo', fetchUserInfo, { ...options });

  return userInfoQuery;
};

export default useUserInfo;

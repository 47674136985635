import React, { useEffect, useMemo, useState } from 'react';

import { Button, Spinner, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import * as Sentry from '@sentry/react';
import { Navigate, useParams, Link as RouterLink } from 'react-router-dom';

import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import { CheckoutStatus } from 'hooks/data/types';
import useUserInfo from 'hooks/data/useUserInfo';
import { ErrorReason } from 'utilities/errors/types';
import { computeLatestAccessibleVersion } from 'utilities/licences';
import licenceVersions from 'utilities/licenceVersions';

const Redirect = () => {
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const id = setTimeout(() => {
      setShouldShowMessage(true);
    }, 10000);

    return () => clearTimeout(id);
  }, []);

  const { data, isLoading } = useUserInfo({ refetchInterval: 1000 });

  const upgradedLicence = useMemo(
    () => data?.licences.map(({ licence }) => licence).find((l) => l.id.toString() === id),
    [data, id]
  );

  const textColor = useColorModeValue('gray.600', 'gray.400');

  if (!id) return <Navigate to="/404" />;

  if (!isLoading) {
    // At least wait for the user info to load the first time
    if (!upgradedLicence) {
      // If the licence is not there still, redirect to 404
      Sentry.captureException(new Error('Upgraded licence with id `${id} not found'), {
        extra: { licenceId: id },
        user: { username: data?.username, id: data?.id },
      });

      return <Navigate to={`/error?reason=${ErrorReason.UPGRADED_LICENCE_NOT_FOUND}`} />;
    }

    if (computeLatestAccessibleVersion(upgradedLicence?.attrs.maintenance_end_date).version === licenceVersions[0].version) {
      return <Navigate to={`/?status=${CheckoutStatus.SUCCESS}`} />;
    }
  }

  return (
    <FullScreenLayout includeFooter={false}>
      <Stack alignItems={'center'} maxW={'container.lg'} mx="auto" pt={'100px'} spacing={6}>
        <Spinner boxSize="100px" speed="1s" />
        <Text color={textColor}>Your transaction is being processed and you will be redirected, please wait...</Text>
        {shouldShowMessage && (
          <Text color={textColor}>
            If you are not redirected automatically,{' '}
            <Button variant="link" as={RouterLink} fontWeight={'semibold'} to={`/?status=${CheckoutStatus.PENDING}`}>
              click here
            </Button>
          </Text>
        )}
      </Stack>
    </FullScreenLayout>
  );
};

export default Redirect;

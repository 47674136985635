import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';

type Props = { title: string };

function PageHead({ title }: Props) {
  const pageTitle = useMemo(
    () => (title ? `${title} | ${window._ENV_.VITE_DOCUMENT_TITLE}` : window._ENV_.VITE_DOCUMENT_TITLE),
    [title]
  );

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content="ecoinvent Accounts" />
    </Helmet>
  );
}

export default PageHead;

import React from 'react';

import { Stack, Button } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { useFormContext } from 'react-hook-form';
import { BiCreditCard } from 'react-icons/bi';
import { BsBasket2Fill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { mixpanelPageNames, stepHeadings } from 'components/LicenceOrderWizard/constants';
import { OrderFormData, PaymentMethod } from 'components/LicenceOrderWizard/types';
import { LicenceType } from 'hooks/data/types';
import useCheckoutMutations from 'hooks/data/useCheckoutMutations';
import useLicenceMutations from 'hooks/data/useLicenceMutations';
import useStripeProducts, { StripeProductMap, productIdMap } from 'hooks/data/useStripeProducts';
import { mapOrderForSubmission } from 'utilities/orders';

type Props = {
  onStepChange: (step: number) => void;
  activeStep: number;
};

const StepFooter = ({ onStepChange, activeStep }: Props) => {
  const { trigger, getValues, watch } = useFormContext<OrderFormData>();
  const { licenceType, paymentMethod } = watch();

  const { data, isLoading: areStripeProductsLoading } = useStripeProducts();
  const { purchaseLicenceWithStripe } = useCheckoutMutations();
  const { orderLicence } = useLicenceMutations();

  const submitQuoteRequest = async (orderData: OrderFormData) => {
    const order = mapOrderForSubmission(orderData);

    try {
      const { status } = await orderLicence.mutateAsync(order);

      if (status !== 201) throw new Error('Order failed');

      mixpanel.track('Order Complete', { LicenceType: licenceType, PaymentMethod: PaymentMethod.Quote });
    } catch {
      mixpanel.track('Order Failed', { LicenceType: licenceType, PaymentMethod: PaymentMethod.Quote });
    }
  };

  const beginStripeCheckout = async () => {
    const checkoutData = data as StripeProductMap;

    const finalProductIds = [
      checkoutData?.[productIdMap[LicenceType.Commercial]].id,
      getValues('includeAddon') && checkoutData?.[productIdMap['EN 15804 Add-on']].id,
    ].filter(Boolean) as string[];

    const checkoutMixpanelData = {
      LicenceType: licenceType,
      paymentMethod: PaymentMethod.CreditCard,
    };

    try {
      await purchaseLicenceWithStripe.mutateAsync({
        product_ids: finalProductIds,
      });
      mixpanel.track('Order Checkout', checkoutMixpanelData);
    } catch {
      mixpanel.track('Order Failed', checkoutMixpanelData);
    }
  };

  const wantsToCheckOut = paymentMethod === PaymentMethod.CreditCard;
  const isAboutToCheckout = activeStep === 1 && wantsToCheckOut;
  const isOnLastStep = activeStep === stepHeadings.length - 1;
  const hasFinished = activeStep === stepHeadings.length;

  const onNext = async () => {
    // manually trigger form validation
    if (!(await trigger())) {
      mixpanel.track('Order Form Next Failed', { Page: mixpanelPageNames[activeStep] });
      return;
    }

    // only track "next" events if we're not on the last step of the flow
    if (!isOnLastStep && !isAboutToCheckout) {
      mixpanel.track('Order Form Next', { Page: mixpanelPageNames[activeStep], LicenceType: licenceType });
    }

    switch (activeStep) {
      case 0:
        break;
      case 1:
        if (wantsToCheckOut) {
          await beginStripeCheckout();
          return;
        }
        break;
      case 2:
        await submitQuoteRequest({
          ...getValues(),
        });
        break;
      default:
        break;
    }

    onStepChange(activeStep + 1);
  };

  const onBack = () => {
    mixpanel.track('Order Form Back', { Page: mixpanelPageNames[activeStep] });
    onStepChange(activeStep - 1);
  };

  return (
    <Stack width="100%" direction="row" justifyContent="space-between">
      <Stack>
        {!hasFinished && activeStep > 0 && (
          <Button variant="subtle" size="sm" onClick={onBack}>
            Back
          </Button>
        )}
      </Stack>

      {hasFinished ? (
        <Button size="sm" as={Link} to="/">
          Back home
        </Button>
      ) : (
        <Button
          size="sm"
          onClick={onNext}
          isDisabled={areStripeProductsLoading}
          leftIcon={isOnLastStep ? <BsBasket2Fill /> : isAboutToCheckout ? <BiCreditCard /> : undefined}
        >
          {isOnLastStep ? 'Submit Order' : isAboutToCheckout ? 'Continue to checkout' : 'Next'}
        </Button>
      )}
    </Stack>
  );
};

export default StepFooter;

import React from 'react';

import { Alert, AlertDescription, AlertIcon, Text } from '@ecoinvent/ui';

import { LicenceReseller } from 'hooks/data/types';

type Props = {
  reseller: LicenceReseller;
};

const ResellerMessage = ({ reseller }: Props) => {
  return (
    <Alert status="info" variant={'left-accent'} size="sm" py={2} borderRadius={'md'}>
      <AlertIcon />
      <AlertDescription>
        <Text>
          This licence was purchased via{' '}
          <Text as="span" fontWeight={'semibold'}>
            {reseller.name}
          </Text>
          . To make changes, please contact them directly.
        </Text>
      </AlertDescription>
    </Alert>
  );
};

export default ResellerMessage;

import React from 'react';

import { HStack, Heading, Link, Stack, Text, useColorModeValue } from '@ecoinvent/ui';
import { Avatar } from '@ecoinvent/ui';

import { Licence } from 'hooks/data/types';
import useUserInfo from 'hooks/data/useUserInfo';

type Props = { licence: Licence };

const LicenceOwner = ({ licence }: Props) => {
  const { data: user } = useUserInfo();
  const isOwner = licence.owner.id === user?.id;
  const fullName = `${licence.owner.first_name} ${licence.owner.last_name}`;

  const helperTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack spacing={3}>
      <Heading size="xs">Administrator</Heading>
      <HStack>
        <Avatar size="md" name={fullName} />
        <Stack spacing={0}>
          <Text fontWeight={'medium'}>
            {fullName} {isOwner && `(You)`}
          </Text>
          <Link href={`mailto:${licence.owner.email}`}>{licence.owner.email}</Link>
        </Stack>
      </HStack>
      {!isOwner && (
        <Text fontSize={'xs'} color={helperTextColor}>
          If you're having problems accessing ecoQuery, please reach out to your administrator for access.
        </Text>
      )}
    </Stack>
  );
};

export default LicenceOwner;

import {
  Button,
  Stack,
  Link,
  Text,
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiCreditCard, BiMessageSquareEdit } from 'react-icons/bi';
import { HiSparkles } from 'react-icons/hi2';

import { CheckoutStatus, FeatureNames, Licence } from 'hooks/data/types';
import useAuthUser from 'hooks/data/useAuthUser';
import useCheckoutMutations from 'hooks/data/useCheckoutMutations';
import useFeatures from 'hooks/data/useFeatures';
import useLicenceMutations from 'hooks/data/useLicenceMutations';
import useLicenceUpgradePriceResponse from 'hooks/data/useLicenceUpgrades';
import licenceVersions from 'utilities/licenceVersions';

import AddonNotSupported from './AddonNotSupported';
import NewLicenceRequired from './NewLicenceRequired';
import UpgradePrice from './UpgradePrice';

type Props = { licence: Licence };

const UpgradeModal = ({ licence }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: authUser } = useAuthUser();

  const maintenanceEndDate = licence.attrs.maintenance_end_date;
  const isUpgradePossible = maintenanceEndDate && new Date(maintenanceEndDate) < new Date(licenceVersions[0].releaseDate);

  const { requestExtension } = useLicenceMutations();
  const { upgradeLicenceWithStripe } = useCheckoutMutations();
  const { data: upgradePriceResponse, isLoading } = useLicenceUpgradePriceResponse({
    id: licence.id.toString(),
    enabled: !!isUpgradePossible && isOpen,
  });

  const licenceHasAddon = licence.groups.some((licenceGroup) => licenceGroup.group.name === 'en15804');
  const isNewOrderRequired = upgradePriceResponse?.status === 204; // means that the licence is too old to be upgraded

  const { data: features } = useFeatures();

  const upgradePrice = upgradePriceResponse?.data;

  const onSubmit = async (upgradeMonths: number) => {
    const resp = await requestExtension.mutateAsync({ licenceId: licence.id.toString(), duration: upgradeMonths });
    mixpanel.track('Maintenance Extension Requested');
    if (resp.status === 201) {
      onClose();
    }
  };

  const startCheckoutSession = async () => {
    mixpanel.track('Maintenance Extension Checkout');
    await upgradeLicenceWithStripe.mutateAsync({
      licenceId: licence.id.toString(),
      success_url: `${window.location.href}licences/${licence.id}/upgrade/redirect`,
      cancel_url: `${window.location.href}?status=${CheckoutStatus.CANCELLED}`,
    });
  };

  return (
    <Stack alignItems={{ base: 'stretch', md: 'flex-end' }}>
      {!licence.has_open_extension_order && isUpgradePossible && (
        <Button
          isDisabled={!authUser?.agreement_accepted}
          onClick={() => {
            mixpanel.track('Extend Maintenance Button Clicked');
            onOpen();
          }}
          size="sm"
          variant="subtle"
          leftIcon={<HiSparkles />}
        >
          Upgrade to {licenceVersions[0].version}
        </Button>
      )}
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Upgrade to {licenceVersions[0].version}</ModalHeader>
          <ModalBody pb={isNewOrderRequired ? 4 : 'auto'}>
            <Stack spacing={5}>
              <Stack>
                <Text>
                  Version {licenceVersions[0].version} brings a wealth of updates to the ecoinvent database, including
                  enhancements in{' '}
                  <Text as="span" fontWeight={'semibold'}>
                    Petroleum and Natural Gas, Agriculture, Electricity, Chemicals, Construction,
                  </Text>{' '}
                  and much more!
                </Text>
                <Button
                  as={Link}
                  variant={'link'}
                  href="https://ecoinvent.org/ecoinvent-v3-10/"
                  isExternal
                  alignSelf={'flex-start'}
                >
                  Learn more about version {licenceVersions[0].version}.
                </Button>
              </Stack>
              {isNewOrderRequired ? (
                <NewLicenceRequired />
              ) : licenceHasAddon ? (
                <AddonNotSupported />
              ) : (
                <UpgradePrice price={upgradePrice?.price} isLoading={isLoading} />
              )}
            </Stack>
          </ModalBody>
          {!isNewOrderRequired && (
            <ModalFooter>
              <ButtonGroup spacing={3} size="sm">
                <Button
                  leftIcon={<BiMessageSquareEdit />}
                  isDisabled={!upgradePrice}
                  type="submit"
                  isLoading={requestExtension.isLoading}
                  onClick={() => onSubmit(upgradePrice?.upgrade_months ?? 0)}
                  variant={licenceHasAddon ? 'solid' : 'subtle'}
                  colorScheme={'blue'}
                >
                  Contact sales
                </Button>
                {!!features?.[FeatureNames.LicenceUpgrades] && (
                  <Button
                    leftIcon={<BiCreditCard />}
                    colorScheme={licenceHasAddon ? 'gray' : 'blue'}
                    isDisabled={!authUser?.agreement_accepted || !upgradePrice || licenceHasAddon}
                    onClick={startCheckoutSession}
                    isLoading={upgradeLicenceWithStripe.isLoading}
                  >
                    Pay with credit card
                  </Button>
                )}
              </ButtonGroup>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default UpgradeModal;

import { useToast } from '@ecoinvent/ui';
import { useMutation } from 'react-query';

import api from 'api/api';

type CheckoutBodyBase = {
  success_url?: string;
  cancel_url?: string;
};

type LicencePurchaseParams = CheckoutBodyBase & {
  product_ids: string[];
};

type LicenceUpgradeParams = CheckoutBodyBase & {
  licenceId: string;
};

const buyLicence = async (checkoutBody: LicencePurchaseParams) => {
  const { data } = await api.post('/shop/licences', checkoutBody);
  window.location.href = data['session_url'];
};

const upgradeLicence = async ({ licenceId, ...rest }: LicenceUpgradeParams) => {
  const { data } = await api.post(`/shop/licences/${licenceId}/upgrades`, rest);
  window.location.href = data['session_url'];
};

const useCheckoutMutations = () => {
  const toast = useToast();
  const onError = () => {
    toast({ status: 'error', description: 'Something went wrong, please try again later', title: 'Oh no...' });
  };

  const purchaseLicenceWithStripe = useMutation(buyLicence, {
    onError,
  });

  const upgradeLicenceWithStripe = useMutation(upgradeLicence, {
    onError,
  });
  return { purchaseLicenceWithStripe, upgradeLicenceWithStripe };
};

export default useCheckoutMutations;

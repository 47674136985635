import {
  TableContainer,
  Table,
  Tr,
  Td,
  Tbody,
  Badge,
  Button,
  Stack,
  Text,
  Icon,
  Heading,
  useColorModeValue,
} from '@ecoinvent/ui';
import { format } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import { FiPlus } from 'react-icons/fi';
import { MdOutlineShoppingBasket } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { ExistingLicenceOrder, OrderStatus } from 'hooks/data/types';
import useAuthUser from 'hooks/data/useAuthUser';

import LicenceTableHead from './LicenceTableHead';
import LicenceTableSkeleton from './LicenceTableSkeleton';

type Props = {
  orders: ExistingLicenceOrder[];
  isLoading: boolean;
};

const LicenceOrderTable = ({ orders, isLoading }: Props) => {
  const { data: authUser } = useAuthUser();

  const helperTextColor = useColorModeValue('gray.500', 'gray.400');

  if (isLoading) {
    return <LicenceTableSkeleton />;
  }

  return orders?.length > 0 ? (
    <TableContainer fontSize={'sm'} px={0} whiteSpace={'break-spaces'} overflowX={'auto'}>
      <Table aria-label="simple table">
        <LicenceTableHead />
        <Tbody>
          {orders.map((row) => {
            const orderStatus =
              row.close_date || row.status === OrderStatus.CLOSED_AUTOMATIC || row.status === OrderStatus.CLOSED;
            return (
              <Tr key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <Td textTransform={'capitalize'} scope="row">
                  {row.licence_type ? 'New licence' : 'Upgrade'}
                </Td>
                <Td align="left">{format(row.created_at, 'PPP')}</Td>
                <Td align="left">
                  <Badge
                    variant="solid"
                    colorScheme={row.status === OrderStatus.EXPIRED ? 'gray' : orderStatus ? 'green' : 'blue'}
                    fontSize={'x-small'}
                    rounded={'full'}
                    px={2}
                  >
                    {row.status === OrderStatus.EXPIRED ? 'Expired' : orderStatus ? 'Completed' : 'In Progress'}
                  </Badge>
                </Td>
                <Td isNumeric textTransform={'capitalize'} pl={0}>
                  <Button size="sm" variant={'link'} as={Link} to={`/orders/${row.id}`}>
                    View Details
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Stack padding={2} alignItems="center" spacing={5} color={helperTextColor}>
      <Icon as={MdOutlineShoppingBasket} fontSize="40px" />
      <Stack alignItems="center" spacing={2}>
        <Heading as="h5" fontSize={'xl'}>
          No orders yet
        </Heading>
        <Text>You haven't placed any orders yet.</Text>
      </Stack>
      <Button
        onClick={() => {
          mixpanel.track('Order Button Clicked', { Page: 'Orders' });
        }}
        as={authUser?.agreement_accepted ? Link : undefined}
        to="/order"
        leftIcon={<FiPlus />}
        isDisabled={!authUser?.agreement_accepted}
      >
        Place new order
      </Button>
    </Stack>
  );
};

export default LicenceOrderTable;

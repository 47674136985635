import React from 'react';

import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@ecoinvent/ui';
import { MdMenu } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';

import logo from 'assets/logo_small.png';
import logoWhite from 'assets/logo_small_white.png';

import SidebarContent from './SidebarContent';

const MobileSideBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bg = useColorModeValue('white', 'whiteAlpha.50');

  const logoSrc = useColorModeValue(logo, logoWhite);

  return (
    <Stack as="nav">
      <HStack display={{ base: 'flex', md: 'none' }} p={3} bg={bg}>
        <IconButton color="inherit" aria-label="open drawer" onClick={onOpen} variant={'ghost'} icon={<Icon as={MdMenu} />} />
        <Box maxW={'153px'}>
          <RouterLink to="/">
            <img className="logo" alt="logo" src={logoSrc} />
          </RouterLink>
        </Box>
      </HStack>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton top="21px" right="9px" />
          <SidebarContent onLinkClick={onClose} />
        </DrawerContent>
      </Drawer>
    </Stack>
  );
};

export default MobileSideBar;

import { useMutation, useQueryClient } from 'react-query';

import api from 'api/api';

import { UserUpdate } from '../types';

export const updateUser = async (user: UserUpdate) => {
  const { data } = await api.post('/user/info', user);
  return data;
};

const useUserMutations = () => {
  const queryClient = useQueryClient();

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userInfo']);
    },
  });

  return { updateUser: updateUserMutation };
};

export default useUserMutations;

import { Badge, Stack, Text, Heading, useDisclosure, Collapse, Icon, useColorModeValue, Button } from '@ecoinvent/ui';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { computeLatestAccessibleVersion } from 'utilities/licences';

import { Licence, User } from '../../hooks/data/types';

import LicenceExpiry from './LicenceExpiry';
import LicenceInfoView from './LicenceInfoView';
import LicenceOwner from './LicenceOwner';
import RenewSubscriptionAlert from './RenewSubscriptionAlert';
import ResellerMessage from './ResellerMessage';
import UpgradeModal from './UpgradeModal';

const LicenceCardItem = ({ user, licence }: { user: User; licence: Licence }) => {
  const hasReseller = !!licence.reseller;
  const isOwner = licence.owner.id === user.id;
  const maintenanceEndDate = licence.attrs.maintenance_end_date || licence.attrs.expiry_date;
  const currentVersion = computeLatestAccessibleVersion(maintenanceEndDate);
  const versionCopy = licence.type === 'subscription' ? 'All versions' : `${currentVersion.version}`;

  const { isOpen, onToggle } = useDisclosure();

  const borderColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');

  return (
    <Stack border="1px solid" borderRadius={'lg'} p={5} borderColor={borderColor}>
      <Stack spacing={1}>
        <Stack
          justifyContent="space-between"
          alignItems={{ base: 'stretch', sm: 'center' }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Stack alignItems={{ base: 'flex-start', sm: 'center' }} direction={{ base: 'column', sm: 'row' }}>
            <Heading as="h4" size="sm">
              <Text as="span" textTransform="capitalize">
                {licence.flavour.display_name} licence -
              </Text>{' '}
              {versionCopy}
            </Heading>
            {licence.has_open_extension_order && (
              <Badge px={2} size="sm">
                Upgrade Requested
              </Badge>
            )}
            {!licence.is_active && (
              <Badge colorScheme={'orange'} px={2} size="sm" fontSize={'xs'}>
                Inactive
              </Badge>
            )}
          </Stack>
          {!hasReseller && isOwner && licence.type === 'perpetual' && <UpgradeModal licence={licence} />}
        </Stack>
      </Stack>
      <Button
        variant="link"
        alignSelf={'flex-start'}
        cursor={'pointer'}
        fontWeight={'semibold'}
        fontSize={'sm'}
        onClick={onToggle}
        leftIcon={<Icon as={isOpen ? FiChevronDown : FiChevronRight} />}
      >
        <Text>Details</Text>
      </Button>
      <Collapse in={isOpen}>
        <Stack px={5} py={2} spacing={6}>
          <LicenceInfoView licence={licence} />
          <Stack spacing={8}>
            <LicenceExpiry licence={licence} />
            <LicenceOwner licence={licence} />
            {licence.reseller && <ResellerMessage reseller={licence.reseller} />}
            {!hasReseller && isOwner && licence.type === 'subscription' && <RenewSubscriptionAlert />}
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default LicenceCardItem;

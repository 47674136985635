import React from 'react';

import { DetailList } from '@ecoinvent/ui';

import { Licence } from 'hooks/data/types';

import { licenceConfig } from './licenceConfig';

type Props = { licence: Licence };

const LicenceInfoView = ({ licence }: Props) => {
  return <DetailList data={licence} config={licenceConfig} rowProps={{ justify: 'space-between' }} />;
};

export default LicenceInfoView;

import { LicenceType } from 'hooks/data/types';

import LicenceTypeDescription from './LicenceTypeDescription';

export const licenceTypeLabels = {
  [LicenceType.Commercial]: 'Commercial',
  [LicenceType.Educational]: 'Educational',
  [LicenceType.Developer]: 'Developer',
  [LicenceType.Enterprise]: 'Enterprise',
};

export const licenceTypeDescriptions = {
  [LicenceType.Commercial]: ['Ideal for individual sustainability professionals', 'Get full access to our extensive database'],
  [LicenceType.Educational]: ['Designed for academic institutions', 'Full access to the database for educational use'],
  [LicenceType.Developer]: [
    'Tailored for developers integrating our data into software tools',
    'Comprehensive solution for your software needs',
  ],
  [LicenceType.Enterprise]: ['Best for large companies requiring multiple users', 'Full database access for your entire team'],
};

export const licenceTypeShortDescriptions = {
  [LicenceType.Commercial]: <LicenceTypeDescription items={licenceTypeDescriptions[LicenceType.Commercial]} />,
  [LicenceType.Educational]: <LicenceTypeDescription items={licenceTypeDescriptions[LicenceType.Educational]} />,
  [LicenceType.Developer]: <LicenceTypeDescription items={licenceTypeDescriptions[LicenceType.Developer]} />,
  [LicenceType.Enterprise]: <LicenceTypeDescription items={licenceTypeDescriptions[LicenceType.Enterprise]} />,
};

// TODO: All of these should be fetched from the backend, now that some of them are dynamic
export const fallbackLicencePrices = {
  [LicenceType.Commercial]: 380000,
  [LicenceType.Educational]: 380000,
  [LicenceType.Developer]: 450000,
  [LicenceType.Enterprise]: 600000,
};

import React from 'react';

import { Box, Link as EcoLink, Heading, Stack, Text, Image } from '@ecoinvent/ui';
import { Link } from 'react-router-dom';

import notFoundArt from 'assets/404.svg';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import PageHead from 'components/shared/PageHead';

const NotFound = () => {
  return (
    <FullScreenLayout>
      <PageHead title="Not Found" />
      <Stack spacing={10} alignItems="center" margin="0 auto" py={10}>
        <Box width="400px" borderRadius={'sm'} overflow={'hidden'} position="relative">
          <Heading fontSize={'9xl'} position={'absolute'} top={'40%'} bottom={0}>
            404
          </Heading>
          <Image position="relative" zIndex={'overlay'} src={notFoundArt} />
        </Box>
        <Heading size="3xl" as="h1">
          Oh No...
        </Heading>
        <Text>The page you're looking for doesn't exist.</Text>
        <EcoLink as={Link} to="/">
          Go to Overview
        </EcoLink>
      </Stack>
    </FullScreenLayout>
  );
};

export default NotFound;

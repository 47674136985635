import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import { LicenceType } from './types';

type StripeProduct = {
  id: string;
  name: string;
  price: number;
  currency: string;
  description: string;
};

export type StripeProductMap = Record<string, StripeProduct>;

export const productIdMap = {
  [LicenceType.Commercial]: 'e7c1544a-1225-4bb4-92e9-35df70d35280',
  ['EN 15804 Add-on']: '5bd60e9c-1cc2-4896-9d52-26c1714c158d',
} as const;

const mapProductsToOptions = (products: StripeProduct[]): StripeProductMap => {
  return products.reduce((map, product) => {
    map[product.id] = product;
    return map;
  }, {} as StripeProductMap);
};

const fetchStripeProducts = async () => {
  const { data } = await api.get('/shop/licences');
  return data;
};

const useStripeProducts = () => {
  return useQuery<StripeProduct[], AxiosError, StripeProductMap>(['products'], () => fetchStripeProducts(), {
    select: mapProductsToOptions,
  });
};

export default useStripeProducts;

import {
  Button,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Image,
  ModalProps,
} from '@ecoinvent/ui';

import loading from 'assets/loading.svg';
import success from 'assets/success.svg';
import { CheckoutStatus } from 'hooks/data/types';

type Props = Omit<ModalProps, 'children'> & {
  status: CheckoutStatus;
};

const LicenceUpgradeModal = ({ status, onClose, ...modalProps }: Props) => {
  return (
    <Stack alignItems={{ base: 'stretch', md: 'flex-end' }}>
      <Modal size="md" onClose={onClose} {...modalProps}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{status === CheckoutStatus.SUCCESS ? 'Success!' : 'Check back soon'}</ModalHeader>
          <ModalBody>
            <Stack alignItems={'center'} textAlign={'center'}>
              {status === CheckoutStatus.SUCCESS ? (
                <Image width={'100px'} src={success} />
              ) : (
                <Image width={'90px'} src={loading} />
              )}

              <Text>
                {status === CheckoutStatus.SUCCESS
                  ? 'Your licence was upgraded successfully.'
                  : "Your transaction is still processing. We'll email you when your upgrade is complete!"}
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Stack direction="row" spacing={3}>
              <Button onClick={onClose}>Close</Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default LicenceUpgradeModal;

import React from 'react';

import { Th, Thead, Tr } from '@ecoinvent/ui';

const LicenceTableHead = () => {
  return (
    <Thead>
      <Tr>
        <Th align="left">Order Type</Th>
        <Th align="left">Placed On</Th>
        <Th align="left">Status</Th>
        <Th align="left" />
      </Tr>
    </Thead>
  );
};

export default LicenceTableHead;

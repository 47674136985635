import { Stack, DetailList } from '@ecoinvent/ui';

import { User } from 'hooks/data/types';

import homeConfig from './homeConfig';

type Props = {
  user: User;
};

const UserProfileView = ({ user }: Props) => {
  return (
    <Stack>
      <DetailList config={homeConfig} data={user} hideFalsyValues />
    </Stack>
  );
};

export default UserProfileView;

import { Avatar, Button, HStack, Icon, Stack, StackProps, Text, useColorModeValue, useMenuButton } from '@ecoinvent/ui';
import { BiChevronDown } from 'react-icons/bi';

type Props = StackProps & { id: string; name?: string; email?: string; isLoading?: boolean; imageSrc?: string };

const UserMenuButton = ({ id, name, email, imageSrc, ...rest }: Props) => {
  const buttonProps = useMenuButton(rest);
  const bg = useColorModeValue('blackAlpha.50', 'whiteAlpha.50');
  const borderColor = useColorModeValue('transparent', 'whiteAlpha.200');

  return (
    <HStack
      {...buttonProps}
      as={Button}
      variant="ghost"
      colorScheme="gray"
      aria-label="account of current user"
      aria-controls={id}
      aria-haspopup="true"
      w="full"
      display="flex"
      alignItems="center"
      justifyContent={'space-between'}
      rounded="lg"
      px="3"
      py="3"
      fontSize="sm"
      _hover={{ bg: 'blackAlpha.100' }}
      _active={{ bg: 'blackAlpha.200' }}
      bg={bg}
      height={'auto'}
      border={'1px solid'}
      borderColor={borderColor}
    >
      <HStack>
        <Avatar size="sm" name={name} src={imageSrc} />
        <Stack spacing={1} alignItems={'flex-start'}>
          <Text fontSize={'14px'} fontWeight="bold">
            {name}
          </Text>
          <Text fontSize={'small'} fontWeight={'normal'}>
            {email}
          </Text>
        </Stack>
      </HStack>
      <Icon as={BiChevronDown} />
    </HStack>
  );
};

export default UserMenuButton;

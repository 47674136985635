import { useCallback, useEffect, useState } from 'react';

import { useAuth, hasAuthParams } from 'react-oidc-context';

import { resetUserData, setupAuthAndMixpanel } from 'utilities/auth/user';

type Props = {
  children: React.ReactNode;
};

const ApiInterceptor = ({ children }: Props) => {
  const [isSetupDone, setIsSetupDone] = useState(false);

  const { isLoading, isAuthenticated, signinRedirect, signinSilent, activeNavigator, user } = useAuth();

  const performAuthSetup = useCallback(async () => {
    if (isLoading || hasAuthParams() || !!activeNavigator) return;

    const clearDataAndSignIn = async () => {
      resetUserData();
      await signinRedirect({ redirect_uri: window.location.href });
    };

    // no user even, no session to refresh, redirect to signin
    if (!user) {
      await clearDataAndSignIn();
      return;
    }

    // The user has an expired access token, let's refresh it
    if (!isAuthenticated) {
      const refreshedUser = await signinSilent();
      if (!refreshedUser) {
        await clearDataAndSignIn();
        return;
      }
    }

    setupAuthAndMixpanel(user);

    setIsSetupDone(true);
  }, [activeNavigator, isAuthenticated, isLoading, signinRedirect, signinSilent, user]);

  useEffect(() => {
    performAuthSetup();
  }, [performAuthSetup]);

  return isSetupDone ? <>{children}</> : null;
};

export default ApiInterceptor;

import React from 'react';

import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@ecoinvent/ui';
import { useFormContext } from 'react-hook-form';

import { OrderFormData } from '../types';

const OrganizationNameField = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OrderFormData>();

  return (
    <Stack spacing={2}>
      <FormControl isInvalid={!!errors.organization}>
        <FormLabel htmlFor="organisationName">Organisation name</FormLabel>
        <Input id="organisationName" placeholder="e.g. ecoinvent" {...register('organization', { required: true })} />
        <FormErrorMessage>Please enter the name of the organisation you're ordering for.</FormErrorMessage>
      </FormControl>
    </Stack>
  );
};

export default OrganizationNameField;

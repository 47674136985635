import React, { useEffect } from 'react';

import { Link as EcoLink, Heading, Stack, Text, Image, Button } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { Link, useSearchParams } from 'react-router-dom';

import exclamation from 'assets/exclamation.svg';
import FullScreenLayout from 'components/shared/Layout/FullScreenLayout';
import PageHead from 'components/shared/PageHead';

const Error = () => {
  const [searchParams] = useSearchParams();

  const reason = searchParams.get('reason');

  useEffect(() => {
    if (!reason) return;

    mixpanel.track('Error Page Visit', { reason });
  }, [reason]);

  return (
    <FullScreenLayout>
      <PageHead title="Error" />
      <Stack spacing={10} alignItems="center" margin="0 auto" py={12}>
        <Image height="250px" src={exclamation} />

        <Heading size="2xl" as="h1">
          Something went wrong
        </Heading>
        <Stack textAlign={'center'}>
          <Text>Please try again later. We are sorry for the inconvenience.</Text>
          <Text>
            If your problem persists, please{' '}
            <Button as={EcoLink} isExternal variant="link" href={`https://ecoinvent.org/contact-us`}>
              contact us
            </Button>
            .
          </Text>
        </Stack>

        <Button as={Link} to="/">
          Go to Overview
        </Button>
      </Stack>
    </FullScreenLayout>
  );
};

export default Error;

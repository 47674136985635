import mixpanel from 'mixpanel-browser';
import { User } from 'oidc-client-ts';

import api from 'api/api';

export const resetUserData = () => {
  mixpanel.reset();
  api.defaults.headers.common.Authorization = undefined;
};

export const setupAuthAndMixpanel = (user: User) => {
  api.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;
  mixpanel.identify(user.profile.email as string);
  mixpanel.people.set({
    $email: user.profile.email,
    $first_name: user.profile.given_name,
    $last_name: user.profile.family_name,
    $picture: user.profile.picture,
  });
};

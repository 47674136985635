import { useEffect } from 'react';

import { Button, Heading, Icon, Stack } from '@ecoinvent/ui';
import { BiReceipt } from 'react-icons/bi';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';

import OrderDetailView from 'components/OrderDetailView';
import PageHead from 'components/shared/PageHead';
import { OrderStatus } from 'hooks/data/types';
import useSpecificLicenceOrder from 'hooks/data/useSpecificLicenceOrder';

const Orders = () => {
  const { id } = useParams();
  const { data: order, isLoading, error } = useSpecificLicenceOrder(id as string);

  const navigate = useNavigate();

  useEffect(() => {
    if ([404, 422].includes(error?.response?.status as number)) {
      navigate('/404', { replace: true });
    }
  }, [error?.response, navigate]);

  return (
    <Stack width="100%" maxWidth="container.sm" spacing={10} py={6}>
      <PageHead title="Home" />
      <PageHead title="Order Details" />
      <Stack spacing={6}>
        <Heading>Order Details</Heading>
        <Stack direction="row" justifyContent="space-between">
          <Button variant={'link'} size="sm" as={Link} leftIcon={<Icon as={FiArrowLeft} fontSize="inherit" />} to="/orders">
            Back to orders
          </Button>
          {order?.status === OrderStatus.CLOSED_AUTOMATIC && order?.invoice?.url && (
            <Button
              variant={'link'}
              size="sm"
              as={Link}
              leftIcon={<Icon as={BiReceipt} fontSize="inherit" />}
              to={order?.invoice?.url as string}
            >
              Invoice
            </Button>
          )}
        </Stack>
        <OrderDetailView order={order} isLoading={isLoading} />
      </Stack>
    </Stack>
  );
};

export default Orders;

import React from 'react';

import { Text, Stack, Table, Thead, Tr, Td, Tbody, Heading, Th, useColorModeValue } from '@ecoinvent/ui';

import AppCard from 'components/shared/AppCard';
import { Address, LicenceType } from 'hooks/data/types';
import useUserInfo from 'hooks/data/useUserInfo';
import { formatPrice } from 'utilities/licences';

import { licenceTypeLabels } from '../licenceData';

type Props = {
  price: number;
  includeAddon?: boolean;
  billingAddress?: Address;
  organisationName?: string;
  licenceType: LicenceType;
};

const OrderSummaryCard = ({ price, includeAddon, billingAddress, organisationName, licenceType }: Props) => {
  const { data: user } = useUserInfo();

  const addressToDisplay = billingAddress || user?.address;
  const organisation = organisationName || user?.organisation_name;

  const borderColor = useColorModeValue('blackAlpha.200', 'whiteAlpha.200');

  return (
    <AppCard border={'1px solid'} bg={'transparent'} borderColor={borderColor}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Heading size="md">Order Summary</Heading>
          <Stack textAlign={'left'} spacing={0}>
            <Text fontSize={'sm'}>
              {user?.first_name} {user?.last_name}
            </Text>
            <Text fontSize={'sm'}>{user?.email}</Text>
            {organisation && <Text fontSize={'sm'}>{organisation}</Text>}
            {addressToDisplay && (
              <Text fontSize={'sm'}>
                {addressToDisplay.street}, {addressToDisplay.city}, {addressToDisplay.zip_code}, {addressToDisplay.country}
              </Text>
            )}
          </Stack>
        </Stack>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th align="left" px={0}>
                Product
              </Th>
              <Th isNumeric px={0}>
                Price (excl. VAT)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr sx={{ td: { px: 0, border: 0 } }}>
              <Td fontWeight={'medium'}>{licenceTypeLabels[licenceType]} Licence</Td>
              <Td isNumeric>{formatPrice(price)}</Td>
            </Tr>
            {includeAddon && (
              <Tr sx={{ td: { px: 0, border: 0 } }}>
                <Td fontWeight={'medium'}>EN 15804 Add-on</Td>
                <Td isNumeric>{formatPrice(60000)}</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        <Stack spacing={4}>
          <Stack flexDirection="row-reverse">
            <Text fontWeight={'semibold'} fontSize={'lg'}>
              Total: {formatPrice(price + (includeAddon ? 60000 : 0))}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </AppCard>
  );
};

export default OrderSummaryCard;

import mixpanel from 'mixpanel-browser';

export const setupAnalyticsIfAllowed = () => {
  if (!window.Cookiebot.consent.statistics) return;

  mixpanel.opt_in_tracking();
};

export const disableAnalytics = () => {
  mixpanel.opt_out_tracking();
};

export const initMixpanel = () =>
  mixpanel.init('4fdd49870cc14052b1bd7d163b6859d4', {
    debug: !import.meta.env.PROD,
    opt_out_tracking_by_default: true,
  });

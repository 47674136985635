import React from 'react';

import { DetailList } from '@ecoinvent/ui';

import { DetailedLicenceOrder } from 'hooks/data/types';

import orderConfig from './orderConfig';

type Props = { order: DetailedLicenceOrder };

const LicenceOrderFields = ({ order }: Props) => {
  return <DetailList config={orderConfig} data={order} rowProps={{ justify: 'space-between' }} />;
};

export default LicenceOrderFields;

import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import { resetUserData } from './user';

const oidcConfig: AuthProviderProps = {
  authority: window._ENV_.VITE_KC_AUTHORITY,
  client_id: 'apollo-ui',
  redirect_uri: window._ENV_.VITE_KC_REDIRECT as string,
  scope: 'openid email profile offline_access',
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  onRemoveUser: resetUserData,
};

export default oidcConfig;

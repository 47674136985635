import React, { useEffect } from 'react';

import {
  Stack,
  FormControl,
  Heading,
  FormLabel,
  RadioGroup,
  HStack,
  Radio,
  Badge,
  FormHelperText,
  Text,
  useColorModeValue,
} from '@ecoinvent/ui';
import { Controller, useFormContext } from 'react-hook-form';

import { FeatureNames, LicenceType } from 'hooks/data/types';
import useFeatures from 'hooks/data/useFeatures';
import useStripeProducts from 'hooks/data/useStripeProducts';
import { priceForLicenceType } from 'utilities/orders/checkout';

import AddressForm from '../fragments/AddressForm';
import OrganizationNameField from '../fragments/OrganizationNameField';
import OrderSummaryCard from '../OrderSummaryCard';
import { OrderFormData, PaymentMethod } from '../types';

const RegularOrderDetails = () => {
  const { data: stripeProducts } = useStripeProducts();
  const { getValues, control, watch, setValue } = useFormContext<OrderFormData>();
  const { licenceType, includeAddon } = getValues();
  const paymentMethod = watch('paymentMethod');

  const { data: features, isLoading } = useFeatures();

  const canCheckout = !!features?.[FeatureNames.OnlinePayments];

  const width = { base: '100%', lg: '50%' };

  const price = priceForLicenceType(licenceType, stripeProducts);

  useEffect(() => {
    if (isLoading) return;

    if (!canCheckout || licenceType === LicenceType.Educational) {
      setValue('paymentMethod', PaymentMethod.Quote);
    }
  }, [canCheckout, setValue, isLoading, licenceType]);

  const grayTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack spacing={10} justify={'space-between'} direction={{ base: 'column', lg: 'row' }}>
      <FormControl as={Stack} spacing={4} maxW={width}>
        <Heading size="sm">Payment method</Heading>
        <FormLabel id="payment-method-radio-buttons" hidden>
          Choose a Payment Method
        </FormLabel>
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field }) => (
            <RadioGroup aria-labelledby="payment-method-radio-buttons" {...field} value={paymentMethod}>
              <Stack spacing={2}>
                {!!canCheckout && (
                  <HStack>
                    <Radio value={PaymentMethod.CreditCard} isDisabled={licenceType !== LicenceType.Commercial}>
                      <Stack spacing={0}>
                        <HStack>
                          <Text>Credit or Debit Card </Text>
                          {licenceType !== LicenceType.Commercial && <Badge variant={'solid'}>Coming soon</Badge>}
                        </HStack>
                        <Text fontSize={'xs'} color={grayTextColor}>
                          Securely pay using your card.
                        </Text>
                      </Stack>
                    </Radio>
                  </HStack>
                )}
                <Radio value={PaymentMethod.Quote} fontWeight={'semibold'}>
                  <Stack spacing={0}>
                    <Text>Get a Quote</Text>
                    <Text fontSize={'xs'} color={grayTextColor}>
                      Need a tailored solution? Let our sales team provide you with a personalised quote.
                    </Text>
                  </Stack>
                </Radio>
              </Stack>
            </RadioGroup>
          )}
        />
        {paymentMethod === PaymentMethod.CreditCard && (
          <FormHelperText>
            Note: If you're part of an organisation that already has an ecoQuery licence, please request a quote as you may be
            eligible for a discount.
          </FormHelperText>
        )}
      </FormControl>
      {paymentMethod === PaymentMethod.CreditCard ? (
        <Stack w="100%">
          <OrderSummaryCard licenceType={LicenceType.Commercial} price={price} includeAddon={includeAddon} />
        </Stack>
      ) : (
        <Stack spacing={4} w="100%">
          <Heading size="sm">Billing Details</Heading>
          <OrganizationNameField />
          <AddressForm />
        </Stack>
      )}
    </Stack>
  );
};

export default RegularOrderDetails;

import { Text, Stack, Heading, useColorModeValue } from '@ecoinvent/ui';
import { useFormContext } from 'react-hook-form';

import useStripeProducts from 'hooks/data/useStripeProducts';
import { isRegularLicenceType } from 'utilities/licences';
import { priceForLicenceType } from 'utilities/orders/checkout';

import AdditionalInfoField from './fragments/AdditionalInfoField';
import OrderSummaryCard from './OrderSummaryCard';
import { OrderFormData } from './types';

const FinalizeStep = () => {
  const { getValues } = useFormContext<OrderFormData>();
  const { data: stripeProducts } = useStripeProducts();

  const { includeAddon, organization, address, licenceType } = getValues();

  const price = priceForLicenceType(licenceType, stripeProducts);

  const helperTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Stack spacing={10} direction={{ base: 'column', lg: 'row' }}>
      <Stack spacing={2} width="100%">
        <OrderSummaryCard
          licenceType={licenceType}
          price={price}
          includeAddon={includeAddon}
          billingAddress={address}
          organisationName={organization}
        />
        <Text fontSize={'xs'} color={helperTextColor}>
          Note: the final price is subject to change. A member of our sales team will reach out to confirm.
        </Text>
      </Stack>
      <Stack spacing={8} w="100%">
        {isRegularLicenceType(licenceType) && (
          <Stack spacing={4} width="100%">
            <Heading size="sm">Additional Comments</Heading>
            <AdditionalInfoField />
          </Stack>
        )}
        <Stack spacing={0}>
          <Text>If everything looks good, request a quote and our sales team will contact you shortly.</Text>
          <Text>Otherwise, click the "Back" button to make changes to your order.</Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FinalizeStep;

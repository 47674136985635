import { useEffect } from 'react';

import { HStack, Heading, Box, Button, Stack, useToast, UseToastOptions, Icon } from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { BiPlus } from 'react-icons/bi';
import { FiArrowRight } from 'react-icons/fi';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import LicenceOrderTable from 'components/LicenceOrderTable';
import LicenceUpgradeModal from 'components/LicenceUpgradeModal';
import AppCard from 'components/shared/AppCard';
import PageHead from 'components/shared/PageHead';
import UserLicenceList from 'components/UserLicenceList';
import UserProfileView from 'components/UserProfileView';
import { CheckoutStatus } from 'hooks/data/types';
import useAuthUser from 'hooks/data/useAuthUser';
import useLicenceOrders from 'hooks/data/useLicenceOrders';
import useUserInfo from 'hooks/data/useUserInfo';

const cancelledToastParams: UseToastOptions = {
  title: 'Upgrade cancelled',
  description: 'Your checkout was cancelled.',
  position: 'top',
  status: 'info',
};

const Overview = () => {
  const { data: user, isLoading } = useUserInfo();
  const { data: orders, isLoading: ordersLoading } = useLicenceOrders();
  const { data: authUser, isLoading: isAuthLoading } = useAuthUser();

  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const status = (searchParams.get('status') ?? '') as CheckoutStatus;
  const shouldRenderToast = status === CheckoutStatus.CANCELLED;
  const shouldRenderModal = [CheckoutStatus.SUCCESS, CheckoutStatus.PENDING].includes(status);
  const id = 'cancel-toast';

  useEffect(() => {
    if (!shouldRenderToast || shouldRenderModal || toast.isActive(id)) return;

    toast({ id, ...cancelledToastParams });
    navigate('/', { replace: true });
  }, [shouldRenderToast, shouldRenderModal, status, toast, navigate]);

  const onClose = () => {
    navigate('/', { replace: true });
  };

  if (isLoading || isAuthLoading || !authUser || !user) {
    return <Box w="100%" />;
  }

  return (
    <Stack width="100%" maxWidth="container.lg" spacing={6} py={6}>
      <PageHead title="Home" />
      <Heading as="h1" size="2xl">
        Hello, {user.first_name}
      </Heading>
      <LicenceUpgradeModal isOpen={shouldRenderModal} onClose={onClose} status={status} />
      {orders && orders.length > 0 && (
        <AppCard>
          <HStack direction="row" justify={'space-between'}>
            <Heading as="h2" size="md">
              Recent orders
            </Heading>

            <Button size="sm" rightIcon={<FiArrowRight />} as={Link} variant={'link'} to="/orders">
              View all
            </Button>
          </HStack>

          <LicenceOrderTable orders={orders.slice(0, 3)} isLoading={ordersLoading} />
        </AppCard>
      )}
      <AppCard>
        <HStack direction="row" justify={'space-between'}>
          <Heading as="h2" size="md">
            Licences
          </Heading>

          <Button
            onClick={() => {
              mixpanel.track('Order Button Clicked', { Page: 'Overview' });
            }}
            size="sm"
            leftIcon={<Icon as={BiPlus} />}
            as={authUser.agreement_accepted ? Link : undefined}
            aria-label="new-licence"
            to="/order"
            isDisabled={!authUser.agreement_accepted}
          >
            New Order
          </Button>
        </HStack>

        <UserLicenceList user={user} />
      </AppCard>

      <AppCard>
        <HStack justifyContent={'space-between'}>
          <Heading size="md" as="h5">
            Account details
          </Heading>

          <Button variant={'link'} size="sm" aria-label="edit" rightIcon={<FiArrowRight />} as={Link} to="/info">
            View all
          </Button>
        </HStack>

        <UserProfileView user={user} />
      </AppCard>
    </Stack>
  );
};

export default Overview;

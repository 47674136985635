import React from 'react';

import { DetailList } from '@ecoinvent/ui';

import { LicenceUpgradeOrder } from 'hooks/data/types';

import upgradeConfig from './maintenanceConfig';

type Props = { order: LicenceUpgradeOrder };

const MaintenanceExtensionFields = ({ order }: Props) => {
  return <DetailList config={upgradeConfig} data={order} rowProps={{ justify: 'space-between' }} />;
};

export default MaintenanceExtensionFields;

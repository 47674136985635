import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'api/api';

import { Feature, FeatureNames } from './types';

const fetchFeatures = async () => {
  const { data } = await api.get('/features');
  return data;
};

type FeatureMap = Record<FeatureNames, boolean>;

const useFeatures = () => {
  return useQuery<Feature[], AxiosError, FeatureMap>('features', fetchFeatures, {
    select: (data) => data.reduce((acc, feature) => ({ ...acc, [feature.name]: feature.value }), {} as FeatureMap),
  });
};

export default useFeatures;

import { LicenceOrder, LicenceType } from 'hooks/data/types';
import { LicencePlan } from 'utilities/orders/types';

export enum PaymentMethod {
  Quote = 'Quote Request',
  CreditCard = 'Credit Card',
}

export type OrderFormData = Pick<LicenceOrder, 'eula_accepted' | 'is_subsequent' | 'organization' | 'address'> & {
  orderDetails?: string;
  includeAddon?: boolean;
  licenceType: LicenceType;
  clientType: 'private' | 'organization';
  plan: LicencePlan;
  paymentMethod: PaymentMethod;
  product_id?: string;
};

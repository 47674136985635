import { useFormContext } from 'react-hook-form';

import { isRegularLicenceType } from 'utilities/licences';

import { OrderFormData } from '../types';

import ManualOrderDetails from './ManualOrderDetails';
import RegularOrderDetails from './RegularOrderDetails';

const OrderDetailStep = () => {
  const formControl = useFormContext<OrderFormData>();
  const { licenceType } = formControl.getValues();

  if (isRegularLicenceType(licenceType)) {
    return <RegularOrderDetails />;
  }

  return <ManualOrderDetails />;
};

export default OrderDetailStep;
